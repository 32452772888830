import { DirectiveBinding } from 'vue/types/options';

export default {
  inserted(textarea: HTMLTextAreaElement, binding: DirectiveBinding) {
    const maxRowsCount: number = Number(binding.value);

    if (isNaN(maxRowsCount) || maxRowsCount < 2) {
      return;
    }

    textarea.addEventListener('input', () => {
      const colsCount = textarea.cols;
      const countOfRowsDividedByUser = textarea.value.split('\n');

      let rows = countOfRowsDividedByUser.length;

      for (let index = 0; index < countOfRowsDividedByUser.length; index++) {
        rows += Math.floor(countOfRowsDividedByUser[index].length / colsCount);
      }

      textarea.rows = rows > maxRowsCount ? maxRowsCount : rows;
    });
  }
};
