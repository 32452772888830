import { IngredientProps } from '@/interfaces/models/ingredient';

export type IngredientGroupsProps = [
  RequiredGroup,
  OptionalGroup,
  OneOfGroup
];

export interface RequiredGroup extends IngredientsGroupProps<GroupTypes.REQUIRED> {
}

export interface OptionalGroup extends IngredientsGroupProps<GroupTypes.OPTIONAL> {
}

export interface OneOfGroup extends IngredientsGroupProps<GroupTypes.ONE_OF> {
}

export interface IngredientsGroupProps<T> {
  id: string;
  ingredients: Array<IngredientProps>;
  type: T | GroupTypes;
  name: string;
}

export enum GroupTypes {
  REQUIRED = 'required',
  OPTIONAL = 'optional',
  ONE_OF = 'one_of'
}
