export default function(value: number | string): string {
  const number: number = Number(value);

  if (window.isNaN(number)) {
    return <string> value;
  }

  if (number % 1 === 0) {
    return String(number);
  }

  return number.toFixed(2);
}
