





































import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'PopupWrapper'
})
export default class PopupWrapper extends Vue {
  private close() {
    this.$emit('close');
  }
}
