import ExpirationDate from '@/models/creditCard/expirationDate';
import HolderName from '@/models/creditCard/holderName';
import Type from '@/models/creditCard/type';
import Cvc from '@/models/creditCard/cvc';
import Number from '@/models/creditCard/number';
import CreditCardTypeRepository from '@/repository/creditCardTypeRepository';

const HIDDEN_NUMBER_CHAR = '*';
const repository = new CreditCardTypeRepository();

export default class CreditCard {
  private readonly _id: string;
  private readonly _number: Number;
  private readonly _expirationDate: ExpirationDate;
  private readonly _cvc: Cvc;
  private readonly _holderName: HolderName;
  private readonly _shouldBeSave: boolean;
  private readonly _type: Type;
  private readonly _encryptedData: any;

  public constructor(
    id: string,
    number: Number,
    expirationDate: ExpirationDate,
    cvc: Cvc,
    holderName: HolderName,
    encryptedData: any,
    shouldBeSave: boolean = false
  ) {
    this._id = id;
    this._number = number;
    this._expirationDate = expirationDate;
    this._cvc = cvc;
    this._holderName = holderName;
    this._shouldBeSave = shouldBeSave;
    this._encryptedData = encryptedData;
    this._type = CreditCard.getTypeFromNumber(this._number.value);
  }

  public static parseNumber(number: string) {
    return number.replace(/[^\d]/g, '');
  }

  public static formatNumber(number: string) {
    const type = CreditCard.getTypeFromNumber(number);

    if (undefined === type) {
      return number;
    }

    return type.group(number).join(' ');
  }

  private static getTypeFromNumber(number: string): Type {
    const type = repository.findByNumber(number);

    if (undefined === type) {
      throw new Error(`Card number "${number}" is invalid. Can\'t resolve card type.`)
    }

    return type;
  }

  public get hiddenNumber(): string {
    const groups = this._type.group(this._number.value);
    const hiddenGroups = [`${groups[0][0]}***`];

    for (let index = 1; index < groups.length - 1; index++) {
      hiddenGroups.push(HIDDEN_NUMBER_CHAR.repeat(groups[index].length));
    }

    hiddenGroups.push(groups[groups.length - 1])

    return hiddenGroups.join(' ');
  }

  public get id(): string {
    return this._id;
  }

  public get number(): Number {
    return this._number;
  }

  public get expirationDate(): ExpirationDate {
    return this._expirationDate;
  }

  public get cvc(): Cvc {
    return this._cvc;
  }

  public get holderName(): HolderName {
    return this._holderName;
  }

  public get encryptedData(): any {
    return this._encryptedData;
  }

  public get shouldBeSave(): boolean {
    return this._shouldBeSave;
  }

  public get type(): Type {
    return this._type;
  }
}
