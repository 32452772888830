import { ONE_HOUR_IN_SECONDS, ONE_MINUTE_IN_SECONDS } from '@/utils/format/date';
import i18n from '@/plugins/vue-i18n';

export const TAKE_AWAY_TIME_SHIFT: ReadonlyArray<number> = Object.freeze([
  0,
  ONE_MINUTE_IN_SECONDS * 5,
  ONE_MINUTE_IN_SECONDS * 10,
  ONE_MINUTE_IN_SECONDS * 15,
  ONE_MINUTE_IN_SECONDS * 30,
  ONE_MINUTE_IN_SECONDS * 45,
  ONE_HOUR_IN_SECONDS,
]);

export const POSITIONS = Object.freeze({
  BOTTOM_LEFT: 'bottom-left',
  TOP_LEFT: 'top-left',
  BOTTOM_RIGHT: 'bottom-right',
  TOP_RIGHT: 'top-right',
  BOTTOM_CENTER: 'bottom-center',
  TOP_CENTER: 'top-center',
  CURSOR: 'cursor',
  SCREEN_CENTER: 'screen-center'
});
