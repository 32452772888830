import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '@/store';

const namespaced: boolean = false;

export interface SocketState {
}

const state: SocketState = {};

const getters: GetterTree<SocketState, RootState> = {};

const actions: ActionTree<SocketState, RootState> = {
  socketAddClientToOrder({ commit }: ActionContext<SocketState, RootState>, orderProps: any) {
    commit('orderHistory/createTakeAway', orderProps);
  },
  socketRemoveClientFromOrder({ commit }: ActionContext<SocketState, RootState>, orderProps: any) {
    // if (orderProps.isSave) {
    //   commit('pos/removeClientFromSavedReceipt', orderProps);
    // } else {
    //   commit('pos/removeClientFromReceipt', orderProps);
    //   commit('pos/removeClientFromActualOrder', orderProps);
    // }
  },
  socketRefundProducts({ commit }: ActionContext<SocketState, RootState>, orderProps: any) {
    // commit('pos/refundProducts', orderProps);
  },
  socketRefundOrder({ commit }: ActionContext<SocketState, RootState>, { id }: { id: string }) {
    // commit('pos/refundOrder', id);
  },
  socketCreateReceipt({ commit }: ActionContext<SocketState, RootState>, orderProps: any) {
    commit('orderHistory/createTakeAway', orderProps);
  },
  socketCreateTakeAwayOrder({ commit }: ActionContext<SocketState, RootState>, orderProps: any) {
    commit('orderHistory/createTakeAway', orderProps);
  },

  socketIssueOrder({ commit }: ActionContext<SocketState, RootState>, { id }: { id: string }) {
    commit('orderHistory/issueOrder', id);
  },
  socketIssueAndPayOrder({ commit }: ActionContext<SocketState, RootState>, takeAwayProps: any) {
    commit('orderHistory/issueAndPayOrder', takeAwayProps);
  },
  socketCancelTakeAwayOrder({ commit }: ActionContext<SocketState, RootState>, { id }: { id: string }) {
    commit('orderHistory/cancelOrder', id);
  },
  socketRemoveTakeAwayOrder({ commit }: ActionContext<SocketState, RootState>, { id }: any) {
    commit('orderHistory/cancelOrder', id);
  },
  socketTakeTakeAwayOrder({ commit }: ActionContext<SocketState, RootState>, takeAwayProps: any) {
    commit('orderHistory/takeOrder', takeAwayProps);
  },
  socketRefuseTakeAwayOrder({ rootState, commit }: any, { id }: { id: string }) {
    commit('orderHistory/refuseOrder', id);
  },
  socketDoneTakeAwayOrder({ commit }: ActionContext<SocketState, RootState>, { id }: { id: string }) {
    commit('orderHistory/doneOrder', id);
  },
  socketUndoneTakeAwayOrder({ commit }: ActionContext<SocketState, RootState>, { id }: { id: string }) {
    commit('orderHistory/undoneOrder', id);
  },
  socketDoneOrderProduct({ commit }: ActionContext<SocketState, RootState>, payload: any) {
    // commit('orderHistory/doneOrderProduct', payload);
  },
  socketUndoneOrderProduct({ commit }: ActionContext<SocketState, RootState>, payload: any) {
    // commit('orderHistory/undoneOrderProduct', payload);
  },

  socketEditClient({ commit }: ActionContext<SocketState, RootState>, clientProps: any) {
    // commit('clients/editClient', clientProps);
  },

  socketCreateMessage({ commit }: ActionContext<SocketState, RootState>, messageProps: any) {
    commit('chat/createMessage', messageProps);
  }
};

const mutations: MutationTree<SocketState> = {
  socketOrderPaidOnlineFailed() {
  },
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
