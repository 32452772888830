import hiddenCreditCardFactory from '@/factories/hiddenCreditCard.factory';
import Place from '@/models/place';
import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '@/store';
import TakeAway from '@/models/takeAway';
import OrderProduct from '@/models/orderProduct';
import takeAwayApi from '@/api/services/takeAway.service';
import Product from '@/models/product';
import Client from '@/models/client';
import cloneDeep from 'lodash.clonedeep';
import eventHelper, {APP_EVENTS} from "@/utils/eventHelper";

const namespaced: boolean = true;

type OrderState = {
  order: TakeAway
};

const state: OrderState = {
  order: new TakeAway(),
};

const getters: GetterTree<OrderState, RootState> = {
  getOrderProductsCountByProduct(state) {
    return (product: Product): number => {
      return state.order.orderProducts.reduce<number>(
        (count: number, orderProduct: OrderProduct) => {
          if (orderProduct.product.id === product.id) {
            count += orderProduct.count;
          }

          return count;
        },
        0,
      );
    };
  },
};

const actions: ActionTree<OrderState, RootState> = {
  async createOrder({ dispatch }: ActionContext<OrderState, RootState>, { order, place, payOnline }: { order: TakeAway, place: Place, payOnline: boolean }) {
    const response = await takeAwayApi.createOrder(order);

    if (!payOnline || 'SUCCESSFUL' === response.status.toUpperCase()) {
      response.order.place = cloneDeep(place);

      dispatch('socketCreateTakeAwayOrder', response.order, { root: true });
    }

    return response;
  },
  async cancelOrder({ commit }: ActionContext<OrderState, RootState>, orderId: string) {
    return takeAwayApi.cancelOrder(orderId);
  },
  async retryPayment({ commit }: ActionContext<OrderState, RootState>, orderId: string) {
    return takeAwayApi.retryPayment(orderId);
  },
  async verifyPhoneConfirmation({ commit }: ActionContext<OrderState, RootState>, payload: { phoneConfirmation: { id: string, code: string }, client: { phone: string, name: string | null } }) {
    const { client, orders, creditCards }: any = await takeAwayApi.verifyPhoneConfirmation(payload);
    const cards = creditCards.map(hiddenCreditCardFactory.createFromRawCreditCard);

    commit('user/setUser', client, { root: true });
    commit('user/setCreditCards', cards, { root: true });
    commit('orderHistory/setOrders', orders, { root: true });
    commit('orderHistory/setIsFetchingOrderHistory', false, { root: true });

    eventHelper.dispatchEvent(
      APP_EVENTS.USER_FETCHED,
      document,
      {
        cancelable: false,
        bubbles: false,
        detail: {
          user: client,
          cards,
          orders,
          failedPaymentOrder: null,
        },
      },
    );
  },
};

const mutations: MutationTree<OrderState> = {
  removeAllOrderProducts(state) {
    state.order.resetProducts();

    window.localStorage.removeItem('jrm.order');
  },
  addOrderProductToOrder(state, orderProduct: OrderProduct) {
    state.order.addOrderProduct(orderProduct);

    window.localStorage.setItem('jrm.order', JSON.stringify(state.order.payload));
  },
  addPromotionProduct(state, { promotion, orderProduct }: any) {
    state.order.addPromotionProduct(promotion.id, orderProduct);

    window.localStorage.setItem('jrm.order', JSON.stringify(state.order.payload));
  },
  changeOrderProductInOrder(state, { oldOrderProductHash, newOrderProduct }: any) {
    state.order.changeOrderProduct(oldOrderProductHash, newOrderProduct);

    window.localStorage.setItem('jrm.order', JSON.stringify(state.order.payload));
  },
  changePromotionOrderProductInOrder(state, { promotionId, orderProduct }: any) {
    state.order.changePromotionOrderProduct(promotionId, orderProduct);

    window.localStorage.setItem('jrm.order', JSON.stringify(state.order.payload));
  },
  incrementOrderProductCountInOrderByOrderProduct(state, orderProduct: OrderProduct) {
    state.order.incrementOrderProductCountByOrderProduct(orderProduct);

    window.localStorage.setItem('jrm.order', JSON.stringify(state.order.payload));
  },
  decrementOrderProductCountInOrderByOrderProduct(state, orderProduct: OrderProduct) {
    state.order.decrementOrderProductCountByOrderProduct(orderProduct);

    window.localStorage.setItem('jrm.order', JSON.stringify(state.order.payload));
  },
  removeOrderProductFromOrder(state, orderProduct: OrderProduct) {
    state.order.removeOrderProduct(orderProduct);

    window.localStorage.setItem('jrm.order', JSON.stringify(state.order.payload));
  },
  removeOrder(state) {
    state.order = new TakeAway();

    window.localStorage.setItem('jrm.order', JSON.stringify(state.order.payload));
  },
  setOrderClient(state, client: Client) {
    state.order.client = client;
  },
  setOrder(state, order: TakeAway) {
    state.order = order;
  },
  resetOrderProducts(state) {
    state.order.resetProducts();
  },
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export const REMOVE_ORDER_MUTATION_TYPE = 'takeAway/order/removeOrder';
