


















































import CreditCardType from '@/components/ui/creditCard/CreditCardType.vue';
import HiddenCreditCard from '@/models/creditCard/hiddenCreditCard';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'CreditCard',
  components: {
    CreditCardType,
  },
})
export default class CreditCard extends Vue {
  @Prop({
    type: HiddenCreditCard,
    required: true,
  })
  private creditCard!: HiddenCreditCard;

  @Prop({
    type: Boolean,
    default: true,
  })
  private withRemoveButton!: boolean;

  private remove() {
    this.$emit('remove');
  }
}
