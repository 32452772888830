import camelCase from 'lodash.camelcase';

const requireModule = require.context('./takeAway', false, /\.ts$/);
const modules: any = {};

requireModule.keys().forEach((fileName: any) => {
  const moduleName = camelCase(
    fileName.replace(/(\.\/|\.ts)/g, '')
  );
  const module = requireModule(fileName);

  modules[moduleName] = module.default || module;
});

export default {
  namespaced: true,
  modules
};
