




























import ActiveOrderList from './components/ActiveOrderList.vue';
import ArchivedOrderList from './components/ArchivedOrderList.vue';
import PopupWrapper from '@/components/ui/PopupWrapper/index.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'OrderHistoryPopup',
  components: {
    PopupWrapper,
    ActiveOrderList,
    ArchivedOrderList,
  }
})
export default class OrderHistoryPopup extends Vue {
  private close() {
    this.$destroy();
  }
}
