import axiosHelper from '@/helpers/axiosHelper';

export default axiosHelper.mergeAxiosRequestTransformerWithDefaults(
  (data: any, headers: any): any => {
    Object.assign(
      headers.common,
      {
        'APP-SOCKET-ID': window.socketId,
      }
    );

    return data;
  }
);
