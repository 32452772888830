



















import ArchivedOrderListItem from './ArchivedOrderListItem.vue';
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component({
  name: 'ArchivedOrderList',
  components: {
    ArchivedOrderListItem
  }
})
export default class ArchivedOrderList extends Vue {
  @Getter('archivedOrders', {
    namespace: 'orderHistory'
  })
  archivedOrders!: any;
}
