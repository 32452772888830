import Ingredient from '@/models/ingredient';
import { GroupTypes, IngredientsGroupProps } from '@/interfaces/models/ingredientGroup';
import { IngredientProps } from '@/interfaces/models/ingredient';

export default class IngredientsGroup {
  public id: string;
  public ingredients: Ingredient[];
  public type: GroupTypes;
  public name: string;

  public constructor(ingredientGroupProps: IngredientsGroupProps<GroupTypes>) {
    this.id = ingredientGroupProps.id;
    this.ingredients = ingredientGroupProps.ingredients.map((ingredientProps: IngredientProps) => {
      return new Ingredient(ingredientProps, ingredientGroupProps.id);
    });
    this.type = ingredientGroupProps.type;
    this.name = ingredientGroupProps.name;
  }

  get isOptional(): boolean {
    return GroupTypes.OPTIONAL === this.type;
  }

  get isRequired(): boolean {
    return GroupTypes.REQUIRED === this.type;
  }

  get isOneOf(): boolean {
    return GroupTypes.ONE_OF === this.type;
  }
}
