















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'PlaceAppLogo',
})
export default class PlaceAppLogo extends Vue {
  @Prop({
    type: String,
    default: null,
  })
  logo!: string|null;
}
