import CreditCard from '@/models/creditCard/creditCard';
import HiddenCreditCard from '@/models/creditCard/hiddenCreditCard';
import Type from '@/models/creditCard/type';
import Types from '@/models/creditCard/types/index';

export default {
  createFromRawCreditCard(card: any): HiddenCreditCard {
    const type: Type = <Type> Types.find((type: Type): boolean => type.type.toLowerCase() === card.brand.toLowerCase());

    return new HiddenCreditCard(
      card.id,
      `${card.firstPartOfNumber}*** **** **** ${card.lastPartOfNumber}`,
      type,
      card.cardHolder,
      card.month,
      card.year,
    );
  },
  createFromCreditCard(creditCard: CreditCard): HiddenCreditCard {
    return new HiddenCreditCard(
      creditCard.id,
      creditCard.hiddenNumber,
      creditCard.type,
      creditCard.holderName.value,
      creditCard.expirationDate.month,
      creditCard.expirationDate.year,
    );
  },
};
