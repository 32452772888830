import orderHistoryService from '@/api/services/orderHistory.service';
import OrderStaff from '@/models/orderStaff';
import { GetterTree, MutationTree, ActionContext } from 'vuex';
import { RootState } from '@/store';
import TakeAway from '@/models/takeAway';

interface OrderHistoryState {
  orders: any[];
  isFetchingOrderHistory: boolean
}

const namespaced: boolean = true;

const getDefaultState = (): OrderHistoryState => ({
  orders: [],
  isFetchingOrderHistory: true
});

const state: OrderHistoryState = getDefaultState();

const getters: GetterTree<OrderHistoryState, RootState> = {
  activeOrders(state) {
    return state.orders.filter(({ isArchive }: TakeAway) => !isArchive);
  },
  archivedOrders(state) {
    return state.orders.filter(({ isArchive }: TakeAway) => isArchive);
  }
};

const actions = {
  async fetchOrderHistory({ commit }: ActionContext<OrderHistoryState, RootState>) {
    commit('setIsFetchingOrderHistory', true);

    const orders = await orderHistoryService.fetchOrderHistory();

    commit('setOrders', orders);
    commit('setIsFetchingOrderHistory', false);
  },
  async getOrderByOrderId({ state }: ActionContext<OrderHistoryState, RootState>, orderId: string) {
    // consts order = state.orders.find(({ id }) => orderId === id);
    //
    // if (undefined !== order) {
    //   return order;
    // }

    return orderHistoryService.fetchOrderByOrderId(orderId);
  },
  async createReview({ commit }: ActionContext<OrderHistoryState, RootState>, payload: any) {
    const review = await orderHistoryService.createReview(payload);

    commit(
      'addReviewToOrder',
      {
        orderId: payload.orderId,
        review
      }
    );
  }
};

const mutations: MutationTree<OrderHistoryState> = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setOrders(state, orders) {
    state.orders = orders;
  },
  addReviewToOrder(state, { orderId, review }) {
    const order = state.orders.find(({ id }) => id === orderId);

    if (undefined !== order) {
      order.review = review;
    }
  },
  takeOrder(state, { id: orderId, staff }: any) {
    const order: any = state.orders.find(({ id }) => id === orderId);

    if (undefined !== order) {
      order.isCooking = true;
      order.staff = new OrderStaff(staff);
    }
  },
  refuseOrder(state, orderId: string) {
    const order: any = state.orders.find(({ id }) => id === orderId);

    if (undefined !== order) {
      order.isCooking = false;
      order.staff = null;
    }
  },
  doneOrder(state, orderId: string) {
    const order: any = state.orders.find(({ id }) => id === orderId);

    if (undefined !== order) {
      order.isDone = true;
      order.isCooking = false;
    }
  },
  undoneOrder(state, orderId: string) {
    const order: any = state.orders.find(({ id }) => id === orderId);

    if (undefined !== order) {
      order.isDone = false;
      order.isCooking = true;
    }
  },
  issueOrder(state, orderId: string) {
    const order: undefined | TakeAway = state.orders.find(({ id }): boolean => id === orderId);

    if (undefined !== order) {
      order.isArchive = true;
    }
  },
  issueAndPayOrder(state, issuedOrder: any) {
    const order: undefined | any = state.orders.find(({ id }): boolean => id === issuedOrder.id);

    if (undefined !== order) {
      order.isArchive = true;
      order.typeOfPayment = issuedOrder.typeOfPayment;
    }
  },
  cancelOrder(state, orderId: string) {
    const order: undefined | TakeAway = state.orders.find(({ id }): boolean => id === orderId);

    if (undefined !== order) {
      order.isArchive = false;
    }
  },
  setIsFetchingOrderHistory(state, isFetchingOrderHistory: boolean) {
    state.isFetchingOrderHistory = isFetchingOrderHistory;
  },
  createTakeAway(state, order: any) {
    for (let i = 0; i < order.products.length; i++) {
      if (order.products[i].product.image.path) {
        order.products[i].product.image = order.products[i].product.image.path;
      }
    }

    order.createdAt = order.createdAt - order.place.timezone;
    order.datetime = order.datetime - order.place.timezone;

    state.orders.unshift(order);
  },
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
