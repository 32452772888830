

















































































import ActiveOrderViewPopup from '@/components/ui/popups/takeAway/ActiveOrderViewPopup/index.vue';
import DefaultImage from '@/components/ui/product/DefaultImage/index.vue';
import getPopupController from '@/lib/vuePopup/index';
import { PopupControllerPublicProperties } from '@/lib/vuePopup/interfaces/index';
import SystemDate from '@/utils/systemDate';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import {ONE_MINUTE_IN_SECONDS} from '@/utils/format/date';

@Component({
  name: 'ActiveOrderListItem',
  components: { DefaultImage },
})
export default class ActiveOrderListItem extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  private order!: any;

  private popupController: PopupControllerPublicProperties = getPopupController();

  private get cost(): string {
    if (this.order.totalSpent === 0) {
      return 'Бесплатно';
    }

    return `${this.order.totalSpent} ${this.order.place.currency}`;
  }

  private get date(): SystemDate {
    return new SystemDate(this.order.datetime * 1000);
  }

  private get minutesToEnd() {
    const secondsToEnd = this.order.datetime - this.utcNowInSeconds;

    if (secondsToEnd <= ONE_MINUTE_IN_SECONDS) {
      return 0;
    }

    return Math.floor(secondsToEnd / ONE_MINUTE_IN_SECONDS);
  }

  @State('utcNowInSeconds', {
    namespace: 'timer'
  })
  utcNowInSeconds!: number;

  private openActiveOrderViewPopup() {
    this.popupController.createPopup(ActiveOrderViewPopup, {
      propsData: {
        order: this.order,
        place: this.order.place,
      }
    });
  }
}
