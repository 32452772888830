import belcard from './belcard';
import maestro from './maestro';
import mastercard from './mastercard';
import mir from './mir';
import visa from './visa';

export default [
  maestro,
  mastercard,
  mir,
  visa,
  belcard,
];
