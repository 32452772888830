import camelCase from 'lodash.camelcase';

const requireModule = require.context('./', false, /\.\/(?!index)([\w\-\.]+)(\.ts)$/);
const modules: { [moduleName: string]: any } = {};

requireModule.keys().forEach(importFilterFromFileByFileName);

function importFilterFromFileByFileName(fileName: string) {
  const moduleName = prepareModuleNameForStoreModules(fileName);
  const module = requireModule(fileName);

  modules[moduleName] = module.default || module;
}

function prepareModuleNameForStoreModules(fileName: string) {
  return camelCase(
    fileName.replace(/(\.\/|\.ts)/g, '')
  );
}

export default modules;
