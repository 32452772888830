import BuyXGetYProduct from '@/models/buyXGetYProduct';
import {BuyXGetYProps} from '@/interfaces/models/promotion';

export default class BuyXGetY {
  public id: string;
  public placeIds: string[];
  public necessaryProducts: BuyXGetYProduct[];
  public receivedProducts: BuyXGetYProduct[];
  public necessaryCount: number;
  public receivedCount: number;
  public name: string;
  public description: string;
  public terms: string;
  public enabled: boolean;
  public clientProgressCount: number;
  public clientProgressReceivedCount: number;
  public image?: string;

  [key: string]: any;

  constructor(
    id: string,
    placeIds: string[],
    necessaryProducts: BuyXGetYProduct[],
    receivedProducts: BuyXGetYProduct[],
    necessaryCount: number,
    receivedCount: number,
    name: string,
    description: string,
    terms: string,
    enabled: boolean,
    clientProgressCount: number,
    clientProgressReceivedCount: number,
    image?: string,
  ) {
    this.id = id;
    this.placeIds = placeIds;
    this.necessaryProducts = necessaryProducts;
    this.receivedProducts = receivedProducts;
    this.necessaryCount = necessaryCount;
    this.receivedCount = receivedCount;
    this.name = name;
    this.description = description;
    this.terms = terms;
    this.enabled = enabled;
    this.clientProgressCount = clientProgressCount;
    this.clientProgressReceivedCount = clientProgressReceivedCount;
    this.image = image;
  }

  public static createFromRequest(buyXGetYProps: BuyXGetYProps): BuyXGetY {
    const necessaryProducts = buyXGetYProps.necessaryProducts.map(BuyXGetYProduct.createFromRequest);
    const receivedProducts = buyXGetYProps.receivedProducts.map(BuyXGetYProduct.createFromRequest);

    return new BuyXGetY(
      buyXGetYProps.id,
      buyXGetYProps.placeIds,
      necessaryProducts,
      receivedProducts,
      buyXGetYProps.necessaryCount,
      buyXGetYProps.receivedCount,
      buyXGetYProps.name,
      buyXGetYProps.description,
      buyXGetYProps.terms,
      buyXGetYProps.enabled,
      buyXGetYProps.clientProgress && buyXGetYProps.clientProgress.clientProgressCount || 0,
      buyXGetYProps.clientProgress && buyXGetYProps.clientProgress.clientProgressReceivedCount || 0,
      receivedProducts[0].image
    )
  }

  get preview(): any {
    if (this.image) {
      return this.image;
    }

    return require('../assets/images/product-placeholder.png');
  }
}
