import SystemDate from '@/utils/systemDate';

const ONE_SECOND_IN_MILLISECONDS: number = 1000;

export default class PlaceDate extends SystemDate {
  constructor(placeTimestamp: number) {
    placeTimestamp = SystemDate.getUTCTimestampFromLocalTimestamp(placeTimestamp * ONE_SECOND_IN_MILLISECONDS);

    super(placeTimestamp);
  }

  static createFromLocalTimestampAndPlaceTimezone(timestamp: number, placeTimezoneInSeconds: number): PlaceDate {
    return new PlaceDate(placeTimezoneInSeconds + timestamp / 1000);
  }
}
