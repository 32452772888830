









































































































import PopupWrapper from '@/components/ui/PopupWrapper/index.vue';
import DefaultImage from '@/components/ui/product/DefaultImage/index.vue';
import { IngredientUnits } from '@/interfaces/models/ingredient';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'OrderProductViewPopup',
  components: {
    DefaultImage,
    PopupWrapper,
  },
})
export default class OrderProductViewPopup extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  private orderProduct!: any;

  @Prop({
    type: String,
    required: true,
  })
  private currency!: string;

  private getUnitTranslate(unit: IngredientUnits) {
    switch (unit) {
      case IngredientUnits.GRAM:
        return this.$t('menu.ingredient.unit.weight.name');
      case IngredientUnits.MILLILITER:
        return this.$t('menu.ingredient.unit.volume.name');
      default:
        return this.$t('menu.ingredient.unit.count.name');
    }
  }

  private close() {
    this.$destroy();
  }
}
