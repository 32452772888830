
















import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'ActiveOrderListOnboarding'
})
export default class ActiveOrderListOnboarding extends Vue {
}
