












import DefaultImage from '@/components/ui/product/DefaultImage/index.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'OrderProductListItemIngredient',
  components: { DefaultImage },
})
export default class OrderProductListItemIngredient extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  ingredient!: any;
}
