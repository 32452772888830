import Type, { CREDIT_CARD_TYPE } from '@/models/creditCard/type';

export default new Type(
  3,
  16,
  /^(2[3-7]|22[2-9]|5[1-5])/,
  /(\d{1,4})(\d{1,4})?(\d{1,4})?(\d{1,4})?/,
  true,
  CREDIT_CARD_TYPE.Master,
  /^5[1-5][0-9]{14}|^(222[1-9]|22[3-9]\\d|2[3-6]\\d{2}|27[0-1]\\d|2720)[0-9]{12}$/,
);
