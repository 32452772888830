


















































































































































































import RouteToPlacePopup from '@/components/ui/popups/takeAway/RouteToPlacePopup/index.vue';
import PopupWrapper from '@/components/ui/PopupWrapper/index.vue';
import OrderProductList from '@/components/ui/takeAway/OrderProductList/OrderProductList.vue';
import getPopupController from '@/lib/vuePopup';
import { PopupControllerPublicProperties } from '@/lib/vuePopup/interfaces';
import reviewValidationModel from '@/validators/models/review';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ActionMethod } from 'vuex';
import { State, Action } from 'vuex-class';

@Component({
  name: 'ReviewAddPopup',
  components: {
    PopupWrapper,
    OrderProductList,
  },
  validations: {
    review: reviewValidationModel,
  },
})
export default class ReviewAddPopup extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  private order!: any;

  private review: any = {
    evaluation: 0,
    text: '',
  };
  private popupController: PopupControllerPublicProperties = getPopupController();
  private isDisplayOrderDetails: boolean = false;

  @State('user', {
    namespace: 'user',
  })
  private user!: any;

  private async createReview() {
    this.$v.$touch();

    if (!this.$v.$invalid) {
      try {
        await this.createReviewAction({
          evaluation: this.review.evaluation,
          text: this.review.text,
          orderId: this.order.id,
        });

        this.close();
      } catch (e) {
      }
    }
  }

  private openRouteToPlacePopup() {
    this.popupController.createPopup(RouteToPlacePopup, {
      propsData: {
        place: this.order.place,
      },
    });
  }

  private toggleOrderDetailsVisibility() {
    this.isDisplayOrderDetails = !this.isDisplayOrderDetails;
  }

  private close() {
    this.$destroy();
  }

  @Action('createReview', {
    namespace: 'orderHistory',
  })
  private createReviewAction!: ActionMethod;
}
