import apiClient from '@/api/axiosClient';
import getSocketIdRequestTransformer from '@/api/requestTransformers/socketId.transformer';
import getMenuTransformer from '@/api/responseTransformers/menu/menu.transformer';
import { AxiosResponse } from 'axios';
import MenuCategory from '@/models/menuCategory';

export default {
  async fetchMenuByPlaceId(placeId: string): Promise<MenuCategory[]> {
    return apiClient.get(`/place/menu/${placeId}`, {
      transformRequest: getSocketIdRequestTransformer,
      transformResponse: getMenuTransformer,
    })
      .then(({ data }: AxiosResponse<MenuCategory[]>) => data);
  }
};
