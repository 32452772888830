












































































import DefaultImage from '@/components/ui/product/DefaultImage/index.vue';
import Ingredient from '@/models/ingredient';
import Place from '@/models/place';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

@Component({
  name: 'OneOfGroup',
  components: { DefaultImage },
})
export default class OneOfGroup extends Vue {
  @Prop({
    type: Ingredient,
    required: true,
  })
  private ingredient!: Ingredient;

  @Prop({
    type: Number,
    default: 0,
  })
  private initSelectedCount!: number;

  @Prop({
    type: Number,
    required: true,
  })
  private ingredientCount!: number;

  private selectedCount: number = this.initSelectedCount;

  @State('selectedPlace', {
    namespace: 'place',
  })
  private selectedPlace!: Place;

  private get canIncrementIngredientCount(): boolean {
    return this.selectedPlace.settings.widget.maxIngredientCount > this.ingredientCount;
  }

  private get isIngredientSelected() {
    return this.selectedCount > 0;
  }

  private incrementIngredientCount() {
    if (this.canIncrementIngredientCount) {
      this.selectedCount++;

      this.$emit('incrementIngredientCount', this.ingredient);
    }
  }

  private decrementIngredientCount() {
    this.selectedCount--;

    this.$emit('decrementIngredientCount', this.ingredient);
  }
}
