import { between, maxLength } from 'vuelidate/lib/validators';

export default {
  evaluation: {
    between: between(1, 10)
  },
  text: {
    maxLength: maxLength(1000)
  }
};
