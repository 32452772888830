import Type, { CREDIT_CARD_TYPE } from '@/models/creditCard/type';

export default new Type(
  3,
  [13, 19],
  /^4/,
  /(\d{1,4})(\d{1,4})?(\d{1,4})?(\d{1,4})?(\d{1,3})?/,
  true,
  CREDIT_CARD_TYPE.Visa,
  /^4\d{12}(\d{3}|\d{6})?$/,
);
