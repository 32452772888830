









import abstractDropdownContent from '@/mixins/abstractDropdownContent';
import Component from 'vue-class-component';

@Component({
  name: 'AbstractAnimateDropdownContent'
})
export default class AbstractAnimateDropdownContent extends abstractDropdownContent {
}
