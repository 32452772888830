import promotionService from '@/api/services/promotion.service';
import { GetterTree, MutationTree, ActionContext } from 'vuex';
import { RootState } from '@/store';
import BuyXGetY from '@/models/buyXGetY';

interface PromotionState {
  promotions: BuyXGetY[];
  isFetchingPromotions: boolean;
}

const namespaced: boolean = true;

const getDefaultState = (): PromotionState => ({
  promotions: [],
  isFetchingPromotions: false,
});

const state: PromotionState = getDefaultState();

const getters: GetterTree<PromotionState, RootState> = {};

const actions = {
  async fetchPromotions({ commit }: ActionContext<PromotionState, RootState>, placeId: string) {
    commit('setIsFetchingPromotions', true);

    try {
      const promotions = await promotionService.fetchPromotionsForPlaceId(placeId);

      commit('setPromotions', promotions);
    } finally {
      commit('setIsFetchingPromotions', false);
    }
  },
};

const mutations: MutationTree<PromotionState> = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setPromotions(state, promotions) {
    state.promotions = promotions;
  },
  setIsFetchingPromotions(state, isFetchingPromotions: boolean) {
    state.isFetchingPromotions = isFetchingPromotions;
  }
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
