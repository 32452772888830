import apiClient from '@/api/axiosClient';
import getClientApiRequestTransformer from '@/api/requestTransformers/clientApi.transformer';
import getSocketIdRequestTransformer from '@/api/requestTransformers/socketId.transformer';
import getTakeAwayTransformer from '@/api/responseTransformers/takeAway/takeAway.transformer';
import { AxiosResponse } from 'axios';

export default {
  async fetchOrderHistory(): Promise<any[]> {
    return apiClient.get(`/client/order/list`, {
      transformRequest: [
        ...getClientApiRequestTransformer,
        ...getSocketIdRequestTransformer,
      ]
    })
      .then(({ data }: AxiosResponse<any[]>) => data);
  },
  async fetchOrderByOrderId(orderId: string): Promise<any[]> {
    return apiClient.get(`/client/order/${orderId}`, {
      transformResponse: getTakeAwayTransformer,
      transformRequest: [
        ...getClientApiRequestTransformer,
        ...getSocketIdRequestTransformer,
      ]
    })
      .then(({ data }: AxiosResponse<any[]>) => data);
  },
  async getById(orderId: string): Promise<any[]> {
    return apiClient.get(`/order/view/${orderId}`, {
      transformRequest: [
        ...getClientApiRequestTransformer,
        ...getSocketIdRequestTransformer,
      ]
    })
      .then(({ data }: AxiosResponse<any[]>) => data);
  },
  async createReview(payload: any): Promise<any[]> {
    return apiClient.post('/client/review/create', payload, {
      transformRequest: [
        ...getClientApiRequestTransformer,
        ...getSocketIdRequestTransformer,
      ]
    })
      .then(({ data }: AxiosResponse<any[]>) => data);
  }
};
