export enum VueEventSubscriberTypes {
  ON = 'ON',
  ONCE = 'ONCE'
}

export interface PopupControllerPublicProperties extends Readonly<any> {
  createPopup: Function;
  havePopupStorageItems: Function;
}

export type NewPopupOptions = {
  listeners?: Array<PopupListener>;
};

export type PopupListener = {
  event: string | Array<string>;
  callback: Function;
  type?: VueEventSubscriberTypes;
};

export * from './popupRenderer';
export * from './popupStorage';
