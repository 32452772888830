import { POSITIONS } from '@/consts';
import { mixins } from 'vue-class-component';
import { mixin as clickaway } from 'vue-clickaway';
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class AbstractDropdown extends mixins(clickaway) {
  @Prop({
    type: Boolean,
    required: true
  })
  displayContent!: boolean;

  @Prop({
    type: Object,
    default() {
      return {};
    }
  })
  contentAttrs!: Record<string, string>;

  @Prop({
    type: String,
    default: POSITIONS.BOTTOM_LEFT
  })
  displayPosition!: string;

  @Prop({
    type: Boolean,
    default: true
  })
  isFullWidth!: boolean;

  currentEvent: MouseEvent | KeyboardEvent | null = null;

  $_abstractDropdown_emitHeaderClickContent(event: MouseEvent) {
    this.$emit('headerClick', event);
  }

  $_abstractDropdown_emitEscKeyDown(event: KeyboardEvent) {
    this.$emit('escKeyDown', event);
  }

  $_abstractDropdown_emitUpKeyDown(event: KeyboardEvent) {
    this.$emit('upKeyDown', event);
  }

  $_abstractDropdown_emitDownKeyDown(event: KeyboardEvent) {
    this.$emit('downKeyDown', event);
  }

  $_abstractDropdown_emitEnterKeyDown(event: KeyboardEvent) {
    this.$emit('enterKeyDown', event);
  }

  $_abstractDropdown_emitClickAway(event: MouseEvent) {
    this.$emit('clickAway', event);
  }

  $_abstractDropdown_emitContext(event: MouseEvent) {
    this.currentEvent = event;

    this.$emit('context', event);
  }
}
