
































import abstractDropdown from '@/mixins/abstractDropdown';
import { Component, Prop } from 'vue-property-decorator';
import AbstractAnimateDropdownContent from './DropdownContent.vue';

const DEFAULT_ANIMATION_NAME = 'el-zoom-in-top';

@Component({
  name: 'AbstractAnimateDropdown',
  components: {
    AbstractAnimateDropdownContent
  }
})
export default class AbstractAnimateDropdown extends abstractDropdown {
  @Prop({
    type: String,
    default: DEFAULT_ANIMATION_NAME
  })
  transitionName!: string;
}
