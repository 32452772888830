



















































































































































import Type from '@/models/creditCard/type';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'CreditCardType',
})
export default class CreditCardType extends Vue {
  @Prop({
    type: Type,
    default: undefined,
  })
  private type!: Type | undefined;
}
