






import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'BasePageSpinner'
})
export default class BasePageSpinner extends Vue {
}
