

































































import ActiveOrderViewPopup from '@/components/ui/popups/takeAway/ActiveOrderViewPopup/index.vue';
import RouteToPlacePopup from '@/components/ui/popups/takeAway/RouteToPlacePopup/index.vue';
import PopupWrapper from '@/components/ui/PopupWrapper/index.vue';
import getPopupController from '@/lib/vuePopup';
import { PopupControllerPublicProperties } from '@/lib/vuePopup/interfaces';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';
import { MutationMethod } from 'vuex';

@Component({
  name: 'OrderSuccessConfirmedPopup',
  components: {
    PopupWrapper
  }
})
export default class OrderSuccessConfirmedPopup extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  private order!: any;

  @Prop({
    type: Object,
    required: true,
  })
  private place!: any;

  private popupController: PopupControllerPublicProperties = getPopupController();

  private created() {
    this.addCreditCard(this.order.creditCard);
  }

  private openActiveOrderViewPopup() {
    this.popupController.createPopup(ActiveOrderViewPopup, {
      propsData: {
        order: this.order,
        place: this.order.place,
      }
    });
  }

  private openRouteToPlacePopup() {
    this.popupController.createPopup(RouteToPlacePopup, {
      propsData: {
        place: this.order.place,
      }
    });
  }

  private close() {
    this.removeOrder();

    this.$emit('close');

    this.$destroy();
  }

  @Mutation('removeOrder', {
    namespace: 'takeAway/order'
  })
  private removeOrder!: MutationMethod;

  @Mutation('addCreditCard', {
    namespace: 'user'
  })
  private addCreditCard!: MutationMethod;
}
