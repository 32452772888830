






































































































































































































































import { ONE_DAY_IN_SECONDS } from '@/utils/format/date';
import OrderProductList from './components/OrderProductList.vue';
import ReviewAddPopup from '@/components/ui/popups/takeAway/ReviewAddPopup/index.vue';
import RouteToPlacePopup from '@/components/ui/popups/takeAway/RouteToPlacePopup/index.vue';
import PopupWrapper from '@/components/ui/PopupWrapper/index.vue';
import getPopupController from '@/lib/vuePopup';
import { PopupControllerPublicProperties } from '@/lib/vuePopup/interfaces';
import OrderStaff from '@/models/orderStaff';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { MutationPayload } from 'vuex';
import { State } from 'vuex-class';

@Component({
  name: 'ArchivedOrderViewPopup',
  components: {
    PopupWrapper,
    OrderProductList,
  }
})
export default class ArchivedOrderViewPopup extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  private order!: any;

  private popupController: PopupControllerPublicProperties = getPopupController();
  private isDisplayOrderDetails: boolean = null === this.order.review;

  private get cost(): string {
    if (this.order.totalSpent === 0) {
      return 'Бесплатно';
    }

    return `${this.order.totalSpent} ${this.order.place.currency}`;
  }

  private get isChatShouldBeDisplay() {
    const secondsAfterOrder = this.utcNowInSeconds - this.order.datetime;

    return secondsAfterOrder < ONE_DAY_IN_SECONDS * 3;
  }

  private get isOrderHasReview(): boolean {
    return null !== this.order.review;
  }

  @State('utcNowInSeconds', {
    namespace: 'timer',
  })
  utcNowInSeconds!: number;

  private created() {
    const unsubscribe = this.$store.subscribe(({ type, payload }: MutationPayload) => {
      if ('orderHistory/doneOrder' === type && payload === this.order.id) {
        this.order.isDone = true;
      } else if ('orderHistory/undoneOrder' === type && payload === this.order.id) {
        this.order.isDone = false;
      } else if ('orderHistory/issueOrder' === type && payload === this.order.id) {
        this.order.isArchive = true;
      } else if ('orderHistory/issueAndPayOrder' === type && payload.id === this.order.id) {
        this.order.isArchive = true;
        this.order.typeOfPayment = payload.typeOfPayment;
      } else if ('orderHistory/chancelOrder' === type && payload === this.order.id) {
        this.order.isArchive = false;
      } else if ('orderHistory/takeOrder' === type && payload.id === this.order.id) {
        this.order.isCooking = true;
        this.order.staff = new OrderStaff(payload.staff);
      } else if ('orderHistory/refuseOrder' === type && payload === this.order.id) {
        this.order.isCooking = false;
        this.order.staff = null;
      } else if ('orderHistory/addReviewToOrder' === type && payload.orderId === this.order.id) {
        this.order.review = payload.review;
      }
    });

    this.$on('hook:beforeDestroy', unsubscribe);
  }

  private openReviewAddPopup() {
    this.popupController.createPopup(ReviewAddPopup, {
      propsData: {
        order: this.order,
      },
    });
  }

  private openRouteToPlacePopup() {
    this.popupController.createPopup(RouteToPlacePopup, {
      propsData: {
        place: this.order.place,
      }
    });
  }

  private toggleOrderDetailsVisibility() {
    this.isDisplayOrderDetails = !this.isDisplayOrderDetails;
  }

  private close() {
    this.$destroy();
  }
}
