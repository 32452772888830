










import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import Place from '@/models/place';

@Component({
  name: 'OrderCookedTime',
})
export default class OrderCookedTime extends Vue {
  private get averageLeadTimeTakeAway() {
    return this.selectedPlace.settings.takeAway.averageLeadTimeTakeAway / 60;
  }

  @State('selectedPlace', {
    namespace: 'place'
  })
  private selectedPlace!: Place;
}
