




















































import TakeAway from '@/models/takeAway';
import {Component, Vue, Watch} from 'vue-property-decorator';
import CodeForm from './components/CodeForm.vue';
import PhoneForm from './components/PhoneForm.vue';
import PopupWrapper from '@/components/ui/PopupWrapper/AuthWrapper.vue';
import {maxLength, minLength, numeric, required} from 'vuelidate/lib/validators';
import phone from '@/validators/rules/phone';
import userService from '@/api/services/user.service';
import BaseAsyncButton from '@/components/base/BaseAsyncButton.vue';
import {Action, State} from 'vuex-class';
import {ActionMethod} from 'vuex';

@Component({
  name: 'LoginPopup',
  components: {
    BaseAsyncButton,
    PopupWrapper,
    CodeForm,
    PhoneForm,
  },
  validations: {
    phone: {
      required,
      phone,
    },
    code: {
      minLength: minLength(5),
      maxLength: maxLength(5),
      required,
      numeric,
    },
  },
})
export default class LoginPopup extends Vue {
  private phone: string = '';
  private code: string = '';
  private isPhoneCodeSent: boolean = false;
  private hasError: boolean = false;

  @State('projectId')
  projectId!: string;

  @State('orders', {
    namespace: 'orderHistory',
  })
  orders!: TakeAway[];

  @Watch('phone')
  private onPhoneChanged() {
    this.hasError = false;
  }

  private async getLoginCodeByUserPhone() {
    this.$v.phone.$touch();

    if (!this.$v.phone.$invalid) {
      try {
        await userService.getLoginCodeByUserPhone({
          phone: this.phone,
          projectId: this.projectId,
        });

        this.isPhoneCodeSent = true;
      } catch (e) {
        this.hasError = true;
      }
    }
  }

  private async login() {
    this.$v.$touch();

    if (!this.$v.$invalid) {
      try {
        await this.loginAction({
          phone: this.phone,
          projectId: this.projectId,
          code: this.code,
        });

        this.close();
      } catch (e) {
        this.code = '';
      }
    }
  }

  @Action('login', {
    namespace: 'user'
  })
  private loginAction!: ActionMethod;

  private close() {
    this.$emit('close');
    this.$destroy();
  }
}
