import IngredientsGroup from '@/models/ingredientGroup';
import OrderProductIngredient from '@/models/orderProductIngredient';
import Product from '@/models/product';
import Ingredient from '@/models/ingredient';
import numberUtils from '@/utils/number.utils';
import stringUtils from '@/utils/string.utils';
import { OrderProductProps } from '@/interfaces/models/orderProduct';

export default class OrderProduct {
  product: Product;
  ingredients: OrderProductIngredient[] = [];
  groupIngredients: any = {
    oneOf: {},
    optional: []
  };
  count: number = 1;

  public constructor(orderProductProps: OrderProductProps) {
    this.product = orderProductProps.product;

    if (undefined !== orderProductProps.ingredients) {
      this.ingredients = orderProductProps.ingredients;
    }
  }

  public static createFromRequestPayload(orderProductProps: any) {
    const product = new Product(orderProductProps.product);
    const ingredients = orderProductProps.ingredients.map(OrderProductIngredient.createByRequestPayload);
    const orderProduct = new OrderProduct({ product, ingredients });

    orderProduct.count = orderProductProps.count;

    return orderProduct;
  }

  public static createFromRestoredProduct(restoredProduct: any, product: Product): OrderProduct | null {
    const orderProduct = new OrderProduct({ product });
    orderProduct.count = restoredProduct.count;

    for (const restoredOptionalIngredient of restoredProduct.groupIngredients.optional) {
      const ingredient = product.optionalIngredients.find((ingredient: Ingredient) => {
        const restoredIngredientHash = `${restoredOptionalIngredient.id}-${restoredOptionalIngredient.ingredientCount}-${restoredOptionalIngredient.cost}-${restoredOptionalIngredient.unitCount}`;
        const ingredientHash = `${ingredient.id}-${ingredient.count}-${ingredient.cost}-${ingredient.unitCount}`;

        return restoredIngredientHash === ingredientHash;
      });

      if (ingredient === undefined) {
        return null;
      }

      for (let i = 0; i < restoredOptionalIngredient.count; i++) {
        orderProduct.incrementOrderProductIngredientCountByIngredient(ingredient);
      }
    }

    for (const groupId in restoredProduct.groupIngredients.oneOf) {
      const group = product.oneOfIngredientGroupsWithIngredients.find((group: IngredientsGroup) => group.id === groupId);

      if (group === undefined) {
        return null;
      }

      const restoredOneOfIngredient = restoredProduct.groupIngredients.oneOf[groupId];
      const ingredient = group.ingredients.find((ingredient: Ingredient) => {
        const restoredIngredientHash = `${restoredOneOfIngredient.id}-${restoredOneOfIngredient.ingredientCount}-${restoredOneOfIngredient.cost}-${restoredOneOfIngredient.unitCount}`;
        const ingredientHash = `${ingredient.id}-${ingredient.count}-${ingredient.cost}-${ingredient.unitCount}`;

        return restoredIngredientHash === ingredientHash;
      });

      if (ingredient === undefined) {
        return null;
      }

      orderProduct.selectOneOfIngredient({ selectedIngredient: ingredient, group });
    }

    return orderProduct;
  }

  get hash() {
    const ingredientsHash = this.ingredientsSortedByHash
      .reduce<string>(
        (hash: string, { ingredient, count }: OrderProductIngredient) => {
          return hash + `-${ingredient.fullHash}*${count}`;
        },
        ''
      );

    return `${this.product.id}${ingredientsHash}`;
  }

  private get ingredientsSortedByHash() {
    return this.ingredients
      .slice()
      .sort(
        (
          { ingredient: firstIngredient }: OrderProductIngredient,
          { ingredient: secondIngredient }: OrderProductIngredient
        ) => stringUtils.sortStrings(firstIngredient.fullHash, secondIngredient.fullHash)
      );
  }

  get sortedIngredientsByName() {
    return this.ingredients
      .slice()
      .sort(
        (
          { ingredient: firstIngredient }: OrderProductIngredient,
          { ingredient: secondIngredient }: OrderProductIngredient
        ) => stringUtils.sortStrings(firstIngredient.name, secondIngredient.name)
      );
  }

  get cost() {
    return numberUtils.correctFloatNumber(
      this.product.cost + this.ingredientsCost
    );
  }

  get ingredientsCost() {
    return numberUtils.correctFloatNumber(
      this.ingredients.reduce<number>(
        (sum: number, { fullCost }: OrderProductIngredient) => sum + fullCost,
        0
      )
    );
  }

  get ingredientsCount(): number {
    return this.ingredients.reduce<number>(
      (count: number, ingredient: OrderProductIngredient) => count + ingredient.count,
      0,
    )
  }

  get isHaveIngredients(): boolean {
    return this.ingredients.length > 0;
  }

  public static createOrderProductWithZeroCount(orderProductProps: OrderProductProps) {
    const orderProduct = new OrderProduct(orderProductProps);

    orderProduct.count = 0;

    return orderProduct;
  }

  public static getFinderOrderProductByOrderProductHash(orderProductHash: string) {
    return ({ hash }: OrderProduct) => hash === orderProductHash;
  }

  public incrementOrderProductIngredientCountByIngredient(neededIngredient: Ingredient) {
    const selectedIngredient = this.ingredients.find(
      OrderProductIngredient.getOrderProductIngredientFinderByIngredient(neededIngredient),
    );

    if (undefined !== selectedIngredient) {
      selectedIngredient.count++;
    } else {
      this.addOrderProductIngredientByIngredient(neededIngredient);
    }

    const selectedGroupIngredient = this.groupIngredients.optional.find(
      OrderProductIngredient.getOrderProductIngredientFinderByIngredient(neededIngredient),
    );

    if (undefined !== selectedGroupIngredient) {
      selectedGroupIngredient.count++;
    } else {
      const orderProductIngredient = new OrderProductIngredient(neededIngredient);

      this.groupIngredients.optional.push(orderProductIngredient);
    }
  }

  public decrementOrderProductIngredientCountByIngredient(ingredient: Ingredient) {
    const ingredientIndex = this.ingredients.findIndex(
      OrderProductIngredient.getOrderProductIngredientFinderByIngredient(ingredient)
    );

    if (-1 !== ingredientIndex) {
      if (this.ingredients[ingredientIndex].count > 1) {
        this.ingredients[ingredientIndex].count--;
      } else {
        this.ingredients.splice(ingredientIndex, 1);
      }
    }

    const groupIngredientIndex = this.groupIngredients.optional.findIndex(
      OrderProductIngredient.getOrderProductIngredientFinderByIngredient(ingredient)
    );

    if (-1 !== groupIngredientIndex) {
      if (this.groupIngredients.optional[groupIngredientIndex].count > 1) {
        this.groupIngredients.optional[groupIngredientIndex].count--;
      } else {
        this.groupIngredients.optional.splice(groupIngredientIndex, 1);
      }
    }
  }

  public selectOneOfIngredient(
    { selectedIngredient, oldSelectedIngredient, group }: { selectedIngredient: Ingredient, oldSelectedIngredient?: Ingredient, group: IngredientsGroup }
  ) {
    const selectedOrderProductIngredient = new OrderProductIngredient(selectedIngredient);

    if (undefined === oldSelectedIngredient) {
      this.ingredients.push(selectedOrderProductIngredient);
    } else {
      const oldOrderProductIngredientIndex = this.ingredients.findIndex(
        OrderProductIngredient.getOrderProductIngredientFinderByIngredient(oldSelectedIngredient)
      );

      if (-1 !== oldOrderProductIngredientIndex) {
        this.ingredients.splice(
          oldOrderProductIngredientIndex,
          1,
          selectedOrderProductIngredient
        );
      } else {
        this.ingredients.push(selectedOrderProductIngredient);
      }
    }

    this.groupIngredients.oneOf[group.id] = selectedOrderProductIngredient;
  }

  private addOrderProductIngredientByIngredient(ingredient: Ingredient) {
    const orderProductIngredient = new OrderProductIngredient(ingredient);

    this.ingredients.push(orderProductIngredient);
  }

  public increment() {
    this.count++;
  }

  public decrement() {
    if (this.count > 0) {
      this.count--;
    }
  }

  public get payload() {
    const oneOf: any = {};

    for (const groupId in this.groupIngredients.oneOf) {
      oneOf[groupId] = this.groupIngredients.oneOf[groupId].payload;
    }

    return {
      count: this.count,
      id: this.product.id,
      categoryId: this.product.category ? this.product.category.id : null,
      groupIngredients: {
        oneOf,
        optional: this.groupIngredients.optional.map(({ payload }: OrderProductIngredient) => payload),
      },
    };
  }
}
