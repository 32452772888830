import Client from '@/models/client';
import OrderProduct from '@/models/orderProduct';
import {OrderType} from '@/interfaces/models/order';

export default class Order {
  client: Client = new Client();
  comment: string = '';
  datetime: number = 0;
  id: string = '';
  orderProducts: OrderProduct[] = [];
  isCooking: boolean = false;
  isDone: boolean = false;
  isArchive: boolean = false;
  type: OrderType = OrderType.TakeAway;
  street: string = '';
  entrance: string = '';
  floor: string = '';
  apartment: string = '';

  public get isTakeAway(): boolean {
    return OrderType.TakeAway === this.type;
  }

  public get isDelivery(): boolean {
    return OrderType.Delivery === this.type;
  }

  public addOrderProduct(addedOrderProduct: OrderProduct) {
    const orderProduct = this.getOrderProductByOrderProductHash(addedOrderProduct.hash);

    if (undefined === orderProduct) {
      this.orderProducts.push(addedOrderProduct);
    } else {
      orderProduct.count += addedOrderProduct.count;
    }
  }

  public changeOrderProduct(oldOrderProductHash: string, newOrderProduct: OrderProduct) {
    const oldOrderProductIndex = this.getOrderProductIndexByOrderProductHash(oldOrderProductHash);
    const orderProduct = this.getOrderProductByOrderProductHash(newOrderProduct.hash);

    if (-1 !== oldOrderProductIndex) {
      if (0 === newOrderProduct.count) {
        this.orderProducts.splice(oldOrderProductIndex, 1);
      } else if (undefined !== orderProduct && oldOrderProductHash === newOrderProduct.hash) {
        this.orderProducts[oldOrderProductIndex].count = newOrderProduct.count;
      } else if (undefined !== orderProduct) {
        orderProduct.count += newOrderProduct.count;

        this.orderProducts.splice(oldOrderProductIndex, 1);
      } else {
        this.orderProducts.splice(oldOrderProductIndex, 1, newOrderProduct);
      }
    }
  }

  public removeOrderProduct(removedOrderProduct: OrderProduct) {
    const orderProductIndex = this.getOrderProductIndexByOrderProductHash(removedOrderProduct.hash);

    if (-1 !== orderProductIndex) {
      if (this.orderProducts[orderProductIndex].count === 1) {
        this.orderProducts.splice(orderProductIndex, 1);
      } else {
        this.orderProducts[orderProductIndex].count -= removedOrderProduct.count;
      }
    }
  }

  public incrementOrderProductCountByOrderProduct({ hash }: OrderProduct) {
    const orderProduct = this.getOrderProductByOrderProductHash(hash);

    if (undefined !== orderProduct) {
      orderProduct.count++;
    }
  }

  public decrementOrderProductCountByOrderProduct({ hash }: OrderProduct) {
    const orderProductIndex = this.getOrderProductIndexByOrderProductHash(hash);

    if (-1 !== orderProductIndex) {
      if (1 === this.orderProducts[orderProductIndex].count) {
        this.orderProducts.splice(orderProductIndex, 1);
      } else {
        this.orderProducts[orderProductIndex].count--;
      }
    }
  }

  public getOrderProductByOrderProductHash(productHash: string): OrderProduct | undefined {
    return this.orderProducts.find(
      OrderProduct.getFinderOrderProductByOrderProductHash(productHash)
    );
  }

  public getOrderProductIndexByOrderProductHash(productHash: string): number {
    return this.orderProducts.findIndex(
      OrderProduct.getFinderOrderProductByOrderProductHash(productHash)
    );
  }
}
