




















import { Component, Vue, Prop } from 'vue-property-decorator';
import PopupWrapper from '@/components/ui/PopupWrapper/index.vue';

@Component({
  name: 'RouteToPlacePopup',
  components: {
    PopupWrapper
  }
})
export default class RouteToPlacePopup extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  private place!: any;

  mounted() {
    window.ymaps.ready(() => {
      const myMap = new window.ymaps.Map('route-map', {
        center: [ 53.90613, 27.544494 ],
        zoom: 9,
        controls: [ 'routeButtonControl' ],
      });
      const control = myMap.controls.get('routeButtonControl');
      control.routePanel.state.set({
        toEnabled: false,
        fromEnabled: true,
        to: this.place.coordinates,
      });
      control.routePanel.options.set({
        allowSwitch: false,
      });
      control.routePanel.geolocate('from');
      control.state.set('expanded', true);
    });
  }

  private close() {
    this.$destroy();
  }
}
