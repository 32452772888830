import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class BaseDropdown extends Vue {
  @Prop({
    type: Boolean,
    default: true
  })
  private isFullWidth!: boolean;

  displayContent: boolean = false;

  @Watch('displayContent', { immediate: true })
  onDisplayContentChanged(value: boolean) {
    this.$_baseDropdown_handleScroll(value);
  }

  $_baseDropdown_toggleContent() {
    this.displayContent = !this.displayContent;

    if (this.displayContent) {
      this.$emit('open');
    } else {
      this.$emit('close');
    }
  }

  $_baseDropdown_closeContent() {
    this.displayContent = false;

    this.$emit('close');
  }

  $_baseDropdown_showContent() {
    this.displayContent = true;

    this.$emit('open');
  }

  $_baseDropdown_handleScroll(value: boolean) {
    if (this.$el) {
      const scrollContent: Element | Window = this.$el.closest('.scroll-content') || window;

      if (value) {
        scrollContent.addEventListener(
          'scroll',
          this.$_baseDropdown_closeContent,
          false
        );
      } else {
        scrollContent.removeEventListener(
          'scroll',
          this.$_baseDropdown_closeContent,
          false
        );
      }
    }
  }
}

