export default class OrderStaff {
  avatar: string | null;
  firstName: string;
  id: string;
  lastName: string;
  position: string | null;

  public constructor(orderStaffProps: any) {
    this.firstName = orderStaffProps.firstName;
    this.id = orderStaffProps.id;
    this.lastName = orderStaffProps.lastName;
    this.avatar = orderStaffProps.avatar;
    this.position = orderStaffProps.position;
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}
