import Order from '@/models/order';
import OrderProduct from '@/models/orderProduct';
import Vue from 'vue';
import numberUtils from '@/utils/number.utils';
import { v4 } from 'uuid';

export default class TakeAway extends Order {
  public placeId: string | null = null;
  public buyXGetY: { [key: string]: OrderProduct } = {};
  public isRemember: boolean = false;
  public phoneConfirmationId = v4();

  public get cost(): number {
    const cost = this.orderProducts.reduce(
      (orderCost: number, { count, cost }: OrderProduct) => orderCost + cost * count,
      0
    );
    const promotionCost = Object.values(this.buyXGetY).reduce(
      (orderCost: number, { count, ingredientsCost }: OrderProduct) => orderCost + ingredientsCost * count,
      0
    );

    return numberUtils.correctFloatNumber(cost + promotionCost);
  }

  public get isHasProducts() {
    return this.orderProducts.length > 0 || Object.values(this.buyXGetY).length > 0;
  }

  public get productsCount() {
    const productCount = this.orderProducts.reduce(
      (count: number, product: OrderProduct): number => count + product.count,
      0,
    );
    const promotionProductCount = Object.values(this.buyXGetY).reduce(
      (count: number, product: OrderProduct): number => count + product.count,
      0,
    );
    return productCount + promotionProductCount;
  }

  public changePromotionOrderProduct(promotionId: string, orderProduct: OrderProduct) {
    if (0 === orderProduct.count) {
      Vue.delete(this.buyXGetY, promotionId)
    } else {
      this.buyXGetY[promotionId] = orderProduct;
    }
  }

  public addPromotionProduct(promotionId: string, orderProduct: OrderProduct) {
    const existenceOrderProduct = this.buyXGetY[promotionId];

    if (undefined !== existenceOrderProduct) {
      existenceOrderProduct.count += orderProduct.count;
    } else {
      Vue.set(this.buyXGetY, promotionId, orderProduct);
    }
  }

  public resetProducts() {
    this.orderProducts = [];
    this.buyXGetY = {};
  }

  public get payload(): any {
    const buyXGetY: any = {};

    for (const promotionId in this.buyXGetY) {
      buyXGetY[promotionId] = this.buyXGetY[promotionId].payload;
    }

    return {
      products: this.orderProducts.map(({ payload }: OrderProduct) => payload),
      buyXGetY,
      expiry: (new Date()).getTime() + 1200000,
    }
  }
}
