import apiKeyHelper from '@/helpers/apiKeyHelper';
import Vue from 'vue';
import Widget from './Widget.vue';
import i18n from './plugins/vue-i18n';
import store from './store';
import './components/base';
import './directives';
import './filters';
import './plugins';
import { mapMutations } from 'vuex';

export const WIDGET_CONTAINER_ID = 'jrm-embeded-content';
const projectId = apiKeyHelper.getApiKeyByWidgetContainerId(WIDGET_CONTAINER_ID);

Vue.config.productionTip = false;

new Vue({
  i18n,
  store,
  render: h => h(Widget),
  created() {
    this.setApiKey(projectId);
  },
  methods: mapMutations([
    'setApiKey'
  ])
}).$mount(`#${WIDGET_CONTAINER_ID}`);
