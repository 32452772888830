import ApiKeyWasNotFoundException from '@/exceptions/apiKeyWasNotFoundException';

export default {
  getApiKeyByWidgetContainerId(widgetContainerId: string) {
    const widgetContainer = document.getElementById(widgetContainerId);

    if (widgetContainer !== null) {
      const projectId = widgetContainer.getAttribute('api-key');

      if (projectId !== null && projectId !== '') {
        return projectId;
      }
    }

    throw new ApiKeyWasNotFoundException();
  }
};
