import apiClient from '@/api/axiosClient';
import getBuyXGetYTransformer from '@/api/responseTransformers/promotion/buyXGetY.transformer';
import { AxiosResponse } from 'axios';
import getClientApiRequestTransformer from '@/api/requestTransformers/clientApi.transformer';
import getSocketIdRequestTransformer from '@/api/requestTransformers/socketId.transformer';

export default {
  async fetchPromotionsForPlaceId(placeId: string): Promise<any> {
    return apiClient.get(`/promotion/buy-x-get-y/place/${placeId}`, {
      transformRequest: [
        ...getClientApiRequestTransformer,
        ...getSocketIdRequestTransformer,
      ],
      transformResponse: getBuyXGetYTransformer,
    })
      .then(({ data }: AxiosResponse) => data);
  }
};
