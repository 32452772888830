












































































































import ArchivedOrderViewPopup from '@/components/ui/popups/takeAway/ArchivedOrderViewPopup/index.vue';
import ReviewAddPopup from '@/components/ui/popups/takeAway/ReviewAddPopup/index.vue';
import DefaultImage from '@/components/ui/product/DefaultImage/index.vue';
import getPopupController from '@/lib/vuePopup';
import { PopupControllerPublicProperties } from '@/lib/vuePopup/interfaces';
import SystemDate from '@/utils/systemDate';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import {ONE_DAY_IN_SECONDS} from '@/utils/format/date';

@Component({
  name: 'ArchivedOrderListItem',
  components: { DefaultImage },
})
export default class ArchivedOrderListItem extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  private order!: any;

  private popupController: PopupControllerPublicProperties = getPopupController();

  private get cost(): string {
    if (this.order.totalSpent === 0) {
      return 'Бесплатно';
    }

    return `${this.order.totalSpent} ${this.order.place.currency}`;
  }

  private get date(): SystemDate {
    return new SystemDate(this.order.datetime * 1000);
  }

  private get isChatShouldBeDisplay() {
    const secondsAfterOrder = this.utcNowInSeconds - this.order.datetime;

    return secondsAfterOrder < ONE_DAY_IN_SECONDS * 3;
  }

  @State('utcNowInSeconds', {
    namespace: 'timer',
  })
  utcNowInSeconds!: number;

  private openReviewAddPopup() {
    this.popupController.createPopup(ReviewAddPopup, {
      propsData: {
        order: this.order,
      },
    });
  }

  private openArchivedOrderViewPopup() {
    this.popupController.createPopup(ArchivedOrderViewPopup, {
      propsData: {
        order: this.order,
        place: this.order.place,
      }
    });
  }
}
