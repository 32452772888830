import name from '@/validators/rules/name';
import phone from '@/validators/rules/phone';
import {numeric, required, requiredIf} from 'vuelidate/lib/validators';

export default {
  client: {
    name: {
      required,
      name
    },
    phone: {
      required,
      phone
    }
  },
  street: {
    requiredIf: requiredIf('isDelivery'),
  },
  entrance: {
    numeric,
  },
  floor: {
    numeric,
  },
};
