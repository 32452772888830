import camelCase from 'lodash.camelcase';
import upperFirst from 'lodash.upperfirst';

export default {
  trim(str: string) {
    return str
      .replace(/(\r\n|\r|\n|\s)+/g, '$1')
      .replace(/\n\s+/g, '\n')
      .replace(/\r\n\s+/g, '\r\n')
      .replace(/\r\s+/g, '\r')
      .trim();
  },
  pascalCase(str: string) {
    return upperFirst(
      camelCase(str)
    );
  },
  t2(value: any) {
    return String(value).padStart(2, '0');
  }
};
