






















































































import OrderProductViewPopup from '@/components/ui/popups/takeAway/OrderProductViewPopup/index.vue';
import DefaultImage from '@/components/ui/product/DefaultImage/index.vue';
import getPopupController from '@/lib/vuePopup';
import { PopupControllerPublicProperties } from '@/lib/vuePopup/interfaces';
import TakeAway from '@/models/takeAway';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Mutation, State } from 'vuex-class';
import Place from '@/models/place';
import OrderProduct from '@/models/orderProduct';
import { MutationMethod } from 'vuex';

@Component({
  name: 'PromotionProductUnit',
  components: {
    DefaultImage,
  },
})
export default class PromotionProductUnit extends Vue {
  @Prop({
    type: OrderProduct,
    required: true
  })
  private orderProduct!: OrderProduct;

  @Prop({
    type: String,
    required: true
  })
  private currency!: string;

  private popupController: PopupControllerPublicProperties = getPopupController();

  private get canIncrementProductCount(): boolean {
    return this.selectedPlace.settings.widget.maxProductCount > this.order.productsCount;
  }

  @State('selectedPlace', {
    namespace: 'place'
  })
  private selectedPlace!: Place;

  @State('order', {
    namespace: 'takeAway/order',
  })
  private order!: TakeAway;

  private openOrderProductViewPopup() {
    this.popupController.createPopup(OrderProductViewPopup, {
      propsData: {
        orderProduct: this.orderProduct,
        currency: this.currency
      }
    });
  }

  private incrementOrderProductCount() {
    if (this.canIncrementProductCount) {
      this.incrementOrderProductCountInOrderByOrderProduct(this.orderProduct);
    }
  }

  @Mutation('incrementOrderProductCountInOrderByOrderProduct', {
    namespace: 'takeAway/order'
  })
  private incrementOrderProductCountInOrderByOrderProduct!: MutationMethod;

  @Mutation('decrementOrderProductCountInOrderByOrderProduct', {
    namespace: 'takeAway/order'
  })
  private decrementOrderProductCountInOrderByOrderProduct!: MutationMethod;
}
