










































import DefaultImage from '@/components/ui/product/DefaultImage/index.vue';
import Ingredient from '@/models/ingredient';
import Place from '@/models/place';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';

@Component({
  name: 'OneOfGroup',
  components: { DefaultImage },
})
export default class OneOfGroup extends Vue {
  @Prop({
    type: Ingredient,
    required: true,
  })
  private ingredient!: Ingredient;

  @Prop({
    type: Boolean,
    required: true,
  })
  private isSelected!: boolean;

  @State('selectedPlace', {
    namespace: 'place',
  })
  private selectedPlace!: Place;

  private select() {
    if (!this.isSelected) {
      this.$emit('select');
    }
  }
}
