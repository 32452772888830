























import ActiveOrderListItem from './ActiveOrderListItem.vue';
import ActiveOrderListOnboarding from './ActiveOrderListOnboarding.vue';
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component({
  name: 'ActiveOrderList',
  components: {
    ActiveOrderListItem,
    ActiveOrderListOnboarding
  }
})
export default class ActiveOrderList extends Vue {
  @Getter('activeOrders', {
    namespace: 'orderHistory'
  })
  activeOrders!: any;
}
