



















import Place from '@/models/place';
import PlaceMapMarker from './PlaceMapMarker.vue';
import { yandexMap } from 'vue-yandex-maps';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'PlacesMap',
  components: {
    yandexMap,
    PlaceMapMarker,
  },
})
export default class PlacesMap extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  private places!: Place[];

  private map: any = {
    settings: {
      apiKey: process.env.VUE_APP_YANDEX_MAP_API_KEY,
      lang: 'ru_RU',
      coordorder: 'latlong',
      enterprise: false,
      version: '2.1',
    },
    center: [ 53.90613, 27.544494 ],
    zoom: 10.5,
  };
}
