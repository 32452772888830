























import Ingredient from '@/models/ingredient';
import IngredientsGroup from '@/models/ingredientGroup';
import OrderProductIngredient from '@/models/orderProductIngredient';
import { Component, Prop, Vue } from 'vue-property-decorator';
import OptionalGroupIngredient from './OptionalGroupIngredient.vue';

@Component({
  name: 'OptionalGroup',
  components: {
    OptionalGroupIngredient,
  },
})
export default class OptionalGroup extends Vue {
  @Prop({
    type: Array,
    default: [],
  })
  selectedOptionalIngredients!: OrderProductIngredient[];
  @Prop({
    type: IngredientsGroup,
    required: true,
    validator(optionalIngredientsGroup: IngredientsGroup): boolean {
      return optionalIngredientsGroup.isOptional;
    },
  })
  private optionalIngredientsGroup!: IngredientsGroup;

  @Prop({
    type: Number,
    required: true,
  })
  private ingredientCount!: number;

  private getSelectedCountByIngredient(ingredient: Ingredient): number {
    const selectedIngredient = this.selectedOptionalIngredients.find(
      (selectedIngredient: OrderProductIngredient): boolean => {
        return selectedIngredient.ingredient.id === ingredient.id;
      },
    );

    return undefined !== selectedIngredient ? selectedIngredient.count : 0;
  }
}
