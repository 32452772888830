import CreditCardTypeRepository from '@/repository/creditCardTypeRepository';
import luhn from 'fast-luhn';

const repository = new CreditCardTypeRepository();

export default class Number {
  private readonly _value: string;

  public constructor(value: string) {
    this._value = Number.parseNumber(value);

    if (!Number.isValid(value)) {
      throw new Error('Number is invalid');
    }
  }

  public static isValid(value: string): boolean {
    const parsedNumber = Number.parseNumber(value);
    const type = repository.findByNumber(parsedNumber);

    if (undefined === type) {
      return false;
    }

    return (!type.luhn || luhn(parsedNumber)) && type.test(parsedNumber);
  }

  public static format(value: string): string {
    const parsedNumber = Number.parseNumber(value);
    const type = repository.findByNumber(parsedNumber);

    if (undefined === type) {
      return value;
    }

    return type.group(parsedNumber).join(' ')
  }

  public static parseNumber(value: string): string {
    return value.replace(/[^\d]/g, '');
  }

  public get value(): string {
    return this._value;
  }
}
