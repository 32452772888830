
















import {ONE_HOUR_IN_SECONDS} from '@/utils/format/date';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import PlaceDate from '@/utils/placeDate';
import Place from '@/models/place';

@Component({
  name: 'OrderTimeUnit'
})
export default class OrderTimeUnit extends Vue {
  @Prop({
    type: Number,
    required: true,
  })
  private timeShift!: number;

  @Prop({
    type: Number,
    required: true
  })
  private selectedOrderDatetime!: number;

  private get isWorkingHour() {
    return this.selectedPlace.isPlaceOpen(this.nowInSelectedPlaceInSeconds + this.orderDateShift);
  }

  private get title() {
    if (this.orderDateShift < ONE_HOUR_IN_SECONDS) {
      return this.$tc('takeAway.popups.order_client_add_popup.time_shift.minute', Math.round(this.orderDateShift / 60));
    }

    if (this.orderDateShift % ONE_HOUR_IN_SECONDS === 0) {
      return this.$tc('takeAway.popups.order_client_add_popup.time_shift.hour', Math.round(this.orderDateShift / ONE_HOUR_IN_SECONDS));
    }

    const hours = Math.floor(this.orderDateShift / 3600);
    const minutes = this.orderDateShift % 3600 / 60;

    return `${hours} ч ${minutes} мин`;
  }

  private get orderDate(): PlaceDate {
    const orderTimestamp = this.nowInSelectedPlaceInSeconds + this.orderDateShift;

    return new PlaceDate(orderTimestamp);
  }

  private get orderDateShift() {
    return this.timeShift + this.selectedPlace.settings.takeAway.averageLeadTimeTakeAway;
  }

  @State('selectedPlace', {
    namespace: 'place'
  })
  private selectedPlace!: Place;

  @Getter('nowInSelectedPlaceInSeconds', {
    namespace: 'timer'
  })
  private nowInSelectedPlaceInSeconds!: number;

  private selectTime() {
    if (this.isWorkingHour) {
      this.$emit('selectTime', this.orderDateShift);
    }
  }
}
