


























































import PlaceAppLogo from '@/components/ui/place/PlaceAppLogo/index.vue';
import Place from '@/models/place';
import { Component, Vue } from 'vue-property-decorator';
import { State, Getter } from 'vuex-class';

@Component({
  name: 'PlaceAlreadyClosedNotice',
  components: { PlaceAppLogo },
})
export default class PlaceAlreadyClosedNotice extends Vue {
  private get timeToClose(): number {
    const timeToCloseInSeconds = this.selectedPlace.getTimeToClose(this.nowInSelectedPlaceInSeconds);

    return Math.floor(timeToCloseInSeconds / 60);
  }

  @State('selectedPlace', {
    namespace: 'place'
  })
  private selectedPlace!: Place;

  @Getter('nowInSelectedPlaceInSeconds', {
    namespace: 'timer',
  })
  private nowInSelectedPlaceInSeconds!: number;

  private mounted(): void {
    if (this.$el && this.$el instanceof HTMLElement) {
      document.body.appendChild(this.$el);
    }
  }

  private close() {
    this.$emit('close');
  }

  private beforeDestroy(): void {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
  }
}
