import { MenuAxiosResponse, MenuResponse } from '@/interfaces/api/menu';
import axiosHelper from '@/helpers/axiosHelper';
import MenuCategory from '@/models/menuCategory';
import { MenuCategoryProps } from '@/interfaces/models/menuCategory';

export default axiosHelper.mergeAxiosResponseTransformerWithDefaults(
  (menuCategories: MenuAxiosResponse): MenuResponse => {
    return menuCategories.map((menuCategoryProps: MenuCategoryProps) => {
      return new MenuCategory(menuCategoryProps);
    });
  }
);
