import * as SocketIOClient from 'socket.io-client';
import Emitter from './emitter';

export default class VueSocketIOListener {
  private static staticEvents: string[] = [
    'connect',
    'error',
    'disconnect',
    'reconnect',
    'reconnect_attempt',
    'reconnecting',
    'reconnect_error',
    'reconnect_failed',
    'connect_error',
    'connect_timeout',
    'connecting',
    'ping',
    'pong'
  ];

  private readonly io: any;
  private readonly emitter: Emitter;

  constructor(io: SocketIOClient.Socket, emitter: Emitter) {
    this.io = io;
    this.register();
    this.emitter = emitter;
  }

  register() {
    this.io.onevent = ({ data: [ event, args ] }: { data: [ string, { data: any, eventId: number } ] }) => {
      this.onEvent(event, args.data);
    };

    VueSocketIOListener.staticEvents.forEach((event: string) => {
      this.io.on(event, (args: any) => {
        this.onEvent(event, args);
      });
    });
  }

  onEvent(event: string, args: any) {
    this.emitter.dispatchStore(event, args);
  }
}
