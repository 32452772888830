






































import {Component, Model, Watch} from 'vue-property-decorator';
import BaseDropdown from '@/mixins/baseDropdown';
import AbstractDropdown from '@/components/abstractDropdowns/AbstractAnimateDropdown/Dropdown.vue';

@Component({
  name: 'StreetTypeAhead',
  components: {
    AbstractDropdown,
  },
})
export default class StreetTypeAhead extends BaseDropdown {
  @Model('input', {
    type: String,
    required: true,
  })
  private streetName!: string;

  private streets: string[] = [];
  private selectedStreet: any | null = null;
  private dropdownWasClosed: boolean = false;
  private isStreetInvalid: boolean = false;
  private preventSearch: boolean = false;
  private contentAttrs: any = {
    class: 'compositedroplist'
  };

  private get bindingValue() {
    return this.streetName;
  }

  private set bindingValue(value: string) {
    this.$emit('input', value);
  }

  @Watch('streetName')
  private async onValueChanged(value: string) {
    if (this.preventSearch) {
      this.preventSearch = false;

      return;
    }

    this.isStreetInvalid = true;

    if (value.length > 0) {
      this.streets = await window.ymaps.suggest(`Беларусь Минск ${value}`)
        .catch(() => {
          this.displayContent = false;

          return [];
        });

      if (!this.dropdownWasClosed) {
        this.showDropdown();
      } else {
        this.dropdownWasClosed = false;
      }
    } else {
      this.streets = [];
      this.displayContent = false;
    }
  }

  private selectStreet(street: any) {
    this.preventSearch = true;
    this.bindingValue = street.displayName;
    this.selectedStreet = street;
    this.isStreetInvalid = false;

    this.dropdownWasClosed = true;
    this.$_baseDropdown_closeContent();
  }

  private closeDropdown() {
    if (null !== this.selectedStreet) {
      this.selectStreet(this.selectedStreet);
    }

    this.dropdownWasClosed = true;
    this.$_baseDropdown_closeContent();
  }

  private showDropdown() {
    if (this.streets.length > 0) {
      this.$_baseDropdown_showContent();
    }
  }
}
