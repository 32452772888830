






















import IngredientsGroup from '@/models/ingredientGroup';
import { Component, Prop, Vue } from 'vue-property-decorator';
import OptionalGroupIngredient from './OptionalGroupIngredient.vue';

@Component({
  name: 'OptionalGroup',
  components: {
    OptionalGroupIngredient,
  },
})
export default class OptionalGroup extends Vue {
  @Prop({
    type: IngredientsGroup,
    required: true,
    validator(optionalIngredientsGroup: IngredientsGroup): boolean {
      return optionalIngredientsGroup.isOptional;
    },
  })
  private optionalIngredientsGroup!: IngredientsGroup;

  @Prop({
    type: Number,
    required: true,
  })
  private ingredientCount!: number;
}
