import Logger from './logger';
import Listener from './listener';
import Emitter from './emitter';
import * as SocketIOClient from 'socket.io-client';
import Vue, { VueConstructor } from 'vue';

export type SocketOptions = {
  socket: SocketIOClient.Socket;
  store: any;
  actionPrefix?: string;
  mutationPrefix?: string;
  eventToMutationTransformer?: (eventName: string) => string;
  eventToActionTransformer?: (eventName: string) => string;
};

export default class VueSocketIO {
  private readonly io: SocketIOClient.Socket;
  private readonly emitter: Emitter;
  private readonly listener: Listener;
  private readonly namespaceName: string = '';
  private readonly useConnectionNamespace: boolean = false;


  constructor({ connection, vuex, options }: any) {
    this.io = connection;
    this.emitter = new Emitter(vuex);
    this.listener = new Listener(this.io, this.emitter);

    this.io.on('connect', () => {
      window.socketId = this.io.id;
    });

    if (options) {
      this.useConnectionNamespace = options.useConnectionNamespace;
      this.namespaceName = options.namespaceName;
    }
  }

  install(Vue: VueConstructor<Vue>) {
    const namespace = this.namespaceName || this.io.nsp.replace('/', '');

    if (this.useConnectionNamespace) {
      if (typeof Vue.prototype.$socket === 'object') {
        Vue.prototype.$socket = {
          ...Vue.prototype.$socket,
          [namespace]: this.io
        };
      } else {
        Vue.prototype.$socket = {
          [namespace]: this.io
        };
      }
    } else {
      Vue.prototype.$socket = this.io;
    }

    Logger.info('Socket.io-Plugin was enable');
  }
}
