import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

export default new VueI18n({
  locale: 'ru',
  messages: {
    'ru': require('@/translations/ru.json'),
    'en': require('@/translations/en.json')
  },
  pluralizationRules: {
    ru(choice: number, choicesLength: number) {
      if (0 === choice) {
        return 0;
      }

      const lastTwoNumbersOfChoice = choice % 100;
      const lastNumberOfChoice = lastTwoNumbersOfChoice % 10;

      if (1 === lastNumberOfChoice && 11 !== lastTwoNumbersOfChoice) {
        return 1;
      } else if (
        2 <= lastNumberOfChoice && lastNumberOfChoice <= 4
        && (12 > lastTwoNumbersOfChoice || lastTwoNumbersOfChoice > 14)
      ) {
        return 2;
      }

      return (choicesLength < 4) ? 2 : 3;
    }
  },
  warnHtmlInMessage: 'error'
});
