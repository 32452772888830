import getClientApiRequestTransformer from '@/api/requestTransformers/clientApi.transformer';
import TakeAway from '@/models/takeAway';
import axiosClient from '@/api/axiosClient';
import { AxiosResponse } from 'axios';
import getSocketIdRequestTransformer from '@/api/requestTransformers/socketId.transformer';

export default {
  async createOrder(order: TakeAway): Promise<any> {
    const buyXGetY = [];

    for (const promotionId in order.buyXGetY) {
      const orderProduct = order.buyXGetY[promotionId];

      buyXGetY.push({
        id: promotionId,
        productId: orderProduct.product.id,
        count: orderProduct.count,
        groupIngredients: orderProduct.groupIngredients,
      });
    }

    return axiosClient.post(
      '/order/create',
      {
        ...order,
        buyXGetY,
      },
      {
        transformRequest: [
          ...getClientApiRequestTransformer,
          ...getSocketIdRequestTransformer,
        ],
      },
    )
      .then(({ data }: AxiosResponse<any>) => data);
  },
  async sendPhoneConfirmation(payload: { phone: string, name: string }): Promise<any> {
    return axiosClient.post(
      '/phone-confirmation/create',
      payload,
      {
        transformRequest: [
          ...getClientApiRequestTransformer,
          ...getSocketIdRequestTransformer,
        ],
      },
    )
      .then(({ data }: AxiosResponse<any>) => data);
  },
  async verifyPhoneConfirmation(payload: { phoneConfirmation: { id: string, code: string }, client: { phone: string, name: string | null } }): Promise<any> {
    return axiosClient.post(
      '/phone-confirmation/verify',
      payload,
      {
        transformRequest: [
          ...getClientApiRequestTransformer,
          ...getSocketIdRequestTransformer,
        ],
      },
    )
      .then(({ data }: AxiosResponse<any>) => {
        window.localStorage.setItem('jrm.accessToken', data.token);

        return {
          client: data.client,
          orders: data.orders,
          creditCards: data.creditCards
        };
      });
  },
  async resendPhoneConfirmation(payload: { phoneConfirmationId: string }): Promise<any> {
    return axiosClient.post(
      '/phone-confirmation/resend',
      payload,
      {
        transformRequest: getSocketIdRequestTransformer,
      },
    )
      .then(({ data }: AxiosResponse<any>) => data);
  },
  async cancelOrder(orderId: string): Promise<any> {
    return axiosClient.patch(
      `/client/order/cancel/${orderId}`,
      null,
      {
        transformRequest: [
          ...getClientApiRequestTransformer,
          ...getSocketIdRequestTransformer,
        ],
      },
    )
      .then(({ data }: AxiosResponse<any>) => data);
  },
  async retryPayment(orderId: string): Promise<any> {
    return axiosClient.post(
      '/order/repeat/payment',
      {
        id: orderId,
      },
      {
        transformRequest: [
          ...getClientApiRequestTransformer,
          ...getSocketIdRequestTransformer,
        ],
      },
    )
      .then(({ data }: AxiosResponse<any>) => data);
  },
};
