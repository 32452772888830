


















import Ingredient from '@/models/ingredient';
import IngredientsGroup from '@/models/ingredientGroup';
import OrderProductIngredient from '@/models/orderProductIngredient';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import OneOfGroupIngredient from './OneOfGroupIngredient.vue';

@Component({
  name: 'OneOfGroup',
  components: {
    OneOfGroupIngredient,
  },
})
export default class OneOfGroup extends Vue {
  @Prop({
    type: IngredientsGroup,
    required: true,
    validator(oneOfIngredientsGroup: IngredientsGroup): boolean {
      return oneOfIngredientsGroup.isOneOf;
    },
  })
  private oneOfIngredientsGroup!: IngredientsGroup;

  @Prop({
    type: Object,
    default: undefined,
  })
  private initSelectedIngredient!: OrderProductIngredient;

  private selectedIngredient: Ingredient = this.initSelectedIngredient.ingredient;

  @Watch('selectedIngredient')
  onSelectedIngredientChange(selectedIngredient: Ingredient, oldSelectedIngredient: Ingredient) {
    this.$emit(
      'replaceSelectedIngredients',
      {
        selectedIngredient,
        oldSelectedIngredient,
        group: this.oneOfIngredientsGroup,
      },
    );
  }

  private selectIngredient(ingredient: Ingredient) {
    this.selectedIngredient = ingredient;
  }
}
