
































import SelectPlacePopup from '@/components/ui/popups/place/SelectPlacePopup/index.vue';
import OrderFailedConfirmedPopup from '@/components/ui/popups/takeAway/OrderFailedConfirmedPopup/index.vue';
import OrderHistoryPopup from '@/components/ui/popups/takeAway/OrderHistoryPopup/index.vue';
import LoginPopup from '@/components/ui/popups/user/LoginPopup/index.vue';
import PlaceAlmostClosedNotice from '@/components/ui/takeAway/PlaceAlmostClosedNotice.vue';
import TakeAwayMenuCartPanel from '@/components/ui/takeAway/TakeAwayMenuCartPanel/index.vue';
import PlaceAlreadyClosedNotice from '@/components/ui/takeAway/TakeAwayMenuCartPanel/PlaceAlreadyClosedNotice.vue';
import TakeAwayMenuCategory from '@/components/ui/takeAway/TakeAwayMenuCategory/index.vue';
import getPopupController from '@/lib/vuePopup/index';
import { PopupControllerPublicProperties } from '@/lib/vuePopup/interfaces/index';
import BuyXGetY from '@/models/buyXGetY';
import OrderProduct from '@/models/orderProduct';
import Product from '@/models/product';
import { REMOVE_ORDER_MUTATION_TYPE } from '@/store/takeAway/order';
import { ONE_MINUTE_IN_SECONDS, ONE_SECOND_IN_MILLISECONDS } from '@/utils/format/date';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { loadYmap } from 'vue-yandex-maps';
import { ActionMethod, MutationMethod, MutationPayload } from 'vuex';
import { Action, Getter, State, Mutation } from 'vuex-class';
import MenuCategory from '@/models/menuCategory';
import TakeAway from '@/models/takeAway';
import Place from '@/models/place';

@Component({
  name: 'Widget',
  components: {
    PlaceAlmostClosedNotice,
    PlaceAlreadyClosedNotice,
    TakeAwayMenuCartPanel,
    TakeAwayMenuCategory,
  }
})
export default class Widget extends Vue {
  private popupController: PopupControllerPublicProperties = getPopupController();
  private placeAlreadyClosedNoticeClosed: boolean = true;
  private placeAlmostClosedNoticeClosed: boolean = true;

  private get isPlaceOpen(): boolean {
    return this.selectedPlace && this.selectedPlace.isPlaceOpen(this.nowInSelectedPlaceInSeconds);
  }

  @State('projectId')
  private projectId!: string;

  @State('menu', {
    namespace: 'takeAway/menu',
  })
  private menu!: MenuCategory[];

  @State('promotions', {
    namespace: 'promotion',
  })
  private promotions!: BuyXGetY[];

  @State('order', {
    namespace: 'takeAway/order',
  })
  private order!: TakeAway;

  @State('selectedPlace', {
    namespace: 'place',
  })
  private selectedPlace!: Place;

  @Getter('getProductByRestoredProduct', {
    namespace: 'takeAway/menu',
  })
  private getProductByRestoredProduct!: (restoredProduct: any) => Product | null;

  @State('isLoadingProjectProps')
  isLoadingProjectProps!: boolean;

  @State('isFetchingMenu', {
    namespace: 'takeAway/menu',
  })
  private isFetchingMenu!: boolean;

  @Getter('nowInSelectedPlaceInSeconds', {
    namespace: 'timer',
  })
  private nowInSelectedPlaceInSeconds!: number;

  @Watch('selectedPlace')
  private onSelectedPlaceChanged(place: Place, oldPlace: Place | null) {
    if (null === oldPlace || oldPlace.id !== place.id) {
      this.$socket.emit('join', place.id);
    }

    if (oldPlace !== null && oldPlace.id !== place.id) {
      this.$socket.emit('leave', oldPlace.id);
    }

    this.fetchMenuByPlaceId(place.id);
    this.fetchPromotions(place.id);
    this.resetOrderProducts();
  }

  @Watch('isPlaceOpen')
  private onIsPlaceOpenChange(isPlaceOpen: number) {
    if (isPlaceOpen) {
      this.closePlaceAlreadyClosedNotice();

      this.$nextTick(() => this.openPlaceAlmostClosedNotice());
    } else {
      this.closePlaceAlmostClosedNotice();

      this.$nextTick(() => this.openPlaceAlreadyClosedNotice());
    }
  }

  @Watch('placeAlmostClosedNoticeClosed')
  private onPlaceAlmostClosedNoticeClosedChange(placeAlmostClosedNoticeClosed: number) {
    if (placeAlmostClosedNoticeClosed && this.isPlaceOpen) {
      const timeToClose = this.selectedPlace.getTimeToClose(this.nowInSelectedPlaceInSeconds);
      let timeToReopen = ONE_MINUTE_IN_SECONDS * ONE_SECOND_IN_MILLISECONDS * 5;

      if (timeToClose < ONE_MINUTE_IN_SECONDS * 10) {
        timeToReopen = ONE_MINUTE_IN_SECONDS * ONE_SECOND_IN_MILLISECONDS * 2;
      }

      setTimeout(
        () => {
          this.placeAlmostClosedNoticeClosed = false;
        },
        timeToReopen,
      );
    }
  }

  @Watch('isFetchingMenu')
  onIsFetchingMenu(isFetchingMenu: boolean, oldIsFetchingMenu: boolean) {
    if (oldIsFetchingMenu && !isFetchingMenu) {
      this.setOrder(this.restoreOrder());
    }
  }

  async created() {
    this.updateIsDesktop();

    loadYmap({
      apiKey: process.env.VUE_APP_YANDEX_MAP_API_KEY,
      lang: 'ru_RU',
      coordorder: 'latlong',
      enterprise: false,
      version: '2.1',
    });

    this.fetchProjectPropsByProjectId(this.projectId);
    this.fetchUser()
      .then((failedPaymentOrder: null | string): void => {
        if (failedPaymentOrder) {
          this.popupController.createPopup(OrderFailedConfirmedPopup, {
            propsData: {
              order: failedPaymentOrder,
              place: this.selectedPlace,
            }
          });
        }
      });

    window.addEventListener('resize', this.updateIsDesktop);
    window.addEventListener('orientationchange', this.updateIsDesktop);

    this.openPlaceAlreadyClosedNoticeIfItNeeded();

    this.openPlaceAlmostClosedNotice();

    const unsubscribe = this.$store.subscribe(({ type }: MutationPayload) => {
      if (REMOVE_ORDER_MUTATION_TYPE === type) {
        document.body.scrollTop = 0;
      }
    });

    this.$on('hook:beforeDestroy', unsubscribe);
  }

  private mounted() {
    const loginButtons = document.getElementsByClassName('jrm-login-button');

    for (const loginButton of loginButtons) {
      loginButton.addEventListener('click', () => {
        this.popupController.createPopup(LoginPopup);
      });
    }

    const orderHistoryButtons = document.getElementsByClassName('jrm-order-history-button');

    for (const orderHistoryButton of orderHistoryButtons) {
      orderHistoryButton.addEventListener('click', () => {
        this.popupController.createPopup(OrderHistoryPopup);
      });
    }

    const selectPlaceButtons = document.getElementsByClassName('jrm-select-place-button');

    for (const selectPlaceButton of selectPlaceButtons) {
      selectPlaceButton.addEventListener('click', () => {
        this.popupController.createPopup(SelectPlacePopup);
      });
    }
  }

  private updateIsDesktop() {
    if (window.screen.width > 600) {
      document.body.classList.add('jrm-desktop');
      document.body.classList.remove('jrm-mobile');
    } else {
      document.body.classList.add('jrm-mobile');
      document.body.classList.remove('jrm-desktop');
    }
  }

  private openPlaceAlreadyClosedNoticeIfItNeeded(): void {
    if (!this.isPlaceOpen) {
      this.placeAlreadyClosedNoticeClosed = false;
    }
  }

  private openPlaceAlreadyClosedNotice(): void {
    this.placeAlreadyClosedNoticeClosed = false;
  }

  private closePlaceAlreadyClosedNotice(): void {
    this.placeAlreadyClosedNoticeClosed = true;
  }

  private openPlaceAlmostClosedNotice(): void {
    if (this.isPlaceOpen) {
      const timeToClose = this.selectedPlace.getTimeToClose(this.nowInSelectedPlaceInSeconds);
      const isWorkingTillEndOfDay = this.selectedPlace.isWorkingTillEndOfDay(this.nowInSelectedPlaceInSeconds);

      if (
        timeToClose < ONE_MINUTE_IN_SECONDS * 30
        && (
          !isWorkingTillEndOfDay
          || (isWorkingTillEndOfDay && !this.selectedPlace.isNextDayWorkingFromStartOfDay)
        )
      ) {
        this.placeAlmostClosedNoticeClosed = false;
      }
    }
  }

  private closePlaceAlmostClosedNotice(): void {
    this.placeAlmostClosedNoticeClosed = true;
  }

  private restoreOrder() {
    const rawOrder = window.localStorage.getItem('jrm.order');

    if (rawOrder === null) {
      return new TakeAway();
    }

    const restoredOrder = JSON.parse(rawOrder);
    const now = new Date();

    if (now.getTime() > restoredOrder.expiry) {
      window.localStorage.removeItem('jrm.order');

      return new TakeAway();
    }

    const order = new TakeAway();

    for (const restoredProduct of restoredOrder.products) {
      const product = this.getProductByRestoredProduct(restoredProduct);

      if (!product) {
        continue;
      }

      const orderProduct = OrderProduct.createFromRestoredProduct(restoredProduct, product);

      if (orderProduct) {
        order.addOrderProduct(orderProduct);
      }
    }

    for (const promotionId in restoredOrder.buyXGetY) {
      const restoredProduct = restoredOrder.buyXGetY[promotionId];
      const product = this.getProductByRestoredProduct(restoredProduct);

      if (!product) {
        continue;
      }

      const orderProduct = OrderProduct.createFromRestoredProduct(restoredProduct, product);

      if (orderProduct) {
        order.addPromotionProduct(promotionId, orderProduct);
      }
    }

    return order;
  }

  @Action('fetchProjectPropsByProjectId')
  private fetchProjectPropsByProjectId!: ActionMethod;

  @Action('fetchMenuByPlaceId', {
    namespace: 'takeAway/menu',
  })
  private fetchMenuByPlaceId!: ActionMethod;

  @Mutation('setBeforeInstallPromptEvent')
  private setBeforeInstallPromptEvent!: MutationMethod;

  @Mutation('resetOrderProducts', {
    namespace: 'takeAway/order',
  })
  private resetOrderProducts!: MutationMethod;

  @Mutation('setOrder', {
    namespace: 'takeAway/order',
  })
  private setOrder!: MutationMethod;

  @Action('fetchUser', {
    namespace: 'user',
  })
  fetchUser!: ActionMethod;

  @Action('fetchPromotions', {
    namespace: 'promotion',
  })
  private fetchPromotions!: ActionMethod;
}
