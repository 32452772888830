import { NonWorkingHours, TimetableProps, WorkingHours } from '@/interfaces/models/timetable';

export default class Timetable {
  public workingHours: WorkingHours;
  public nonWorkingHours: NonWorkingHours;

  public constructor(timetableProps: TimetableProps) {
    this.workingHours = timetableProps.workingHours;
    this.nonWorkingHours = timetableProps.nonWorkingHours;
  }

  public get workingFromHour() {
    return this.workingHours[0] / 3600;
  }

  public get workingToHour() {
    return this.workingHours[1] / 3600;
  }

  public get isFullDayWorking() {
    return this.workingHours[1] - this.workingHours[0] - this.breakDuration === 86400;
  }

  public get isWorkingTillEndOfDay() {
    return this.workingHours[1] === 86400;
  }

  public get isWorkingFromStartOfDay() {
    return this.workingHours[0] === 0;
  }

  public get breakDuration() {
    return this.nonWorkingHours.reduce((duration: number, nonWorkingHours: any) => {
      if (nonWorkingHours !== null) {
        duration += nonWorkingHours[1] - nonWorkingHours[0];
      }

      return duration;
    }, 0);
  }

  public getTimeToOpen(seconds: number) {
    if (this.isCloseUntilTomorrow(seconds)) {
      return 86400 - seconds;
    }

    const breakRange = this.getBreakBySeconds(seconds);

    if (breakRange) {
      return breakRange[1] - seconds;
    }

    return 0;
  }

  public getTimeToClose(seconds: number) {
    if (this.isCloseUntilTomorrow(seconds)) {
      return 0;
    }

    const breakRange = this.getBreakBySeconds(seconds);

    if (breakRange) {
      return breakRange[1] - seconds;
    }

    return this.workingHours[1] - seconds;
  }

  // Todo: use high order functions for find
  public getBreakBySeconds(seconds: number) {
    if (seconds < this.workingHours[0]) {
      return [ 0, this.workingHours[0] ];
    } else if (seconds > this.workingHours[1]) {
      return [ this.workingHours[1], this.workingHours[1] + this.workingHours[0] ];
    }

    return this.nonWorkingHours.find((nonWorkingHours: number[] | null) => {
      return nonWorkingHours !== null
        && nonWorkingHours[0] <= seconds
        && seconds < nonWorkingHours[1];
    });
  }

  public isWorkingHour(hour: number) {
    const hourInSeconds = hour * 3600;

    return this.isWorkingTime(hourInSeconds);
  }

  public isWorkingTime(seconds: number) {
    return this.isWorkingHoursContain(seconds) && !this.isNonWorkingHoursContain(seconds);
  }

  public isWorkingHoursContain(seconds: number) {
    return this.workingHours[0] <= seconds && seconds < this.workingHours[1];
  }

  public isNonWorkingHoursContain(seconds: number) {
    return this.nonWorkingHours.some((nonWorkingHours: number[] | null) => {
      if (nonWorkingHours !== null) {
        return nonWorkingHours[0] <= seconds && seconds < nonWorkingHours[1];
      }

      return false;
    });
  }

  public isCloseUntilTomorrow(seconds: number) {
    return this.workingHours[1] <= seconds;
  }
}
