import PlaceDate from '@/utils/placeDate';
import VueI18n from 'vue-i18n';
import i18n from '@/plugins/vue-i18n';
import SystemDate from '@/utils/systemDate';

export const ONE_DAY_IN_MILLISECONDS: number = 86400000;
export const ONE_DAY_IN_SECONDS: number = 86400;
export const ONE_MINUTE_IN_SECONDS: number = 60;
export const ONE_SECOND_IN_MILLISECONDS: number = 1000;
export const ONE_HOUR_IN_SECONDS: number = 3600;

export default {
  getHumanDateString(date: SystemDate | PlaceDate, todayTimestamp: number): string | VueI18n.TranslateResult {
    const yesterday: number = todayTimestamp - ONE_DAY_IN_MILLISECONDS;
    const tomorrow: number = todayTimestamp + ONE_DAY_IN_MILLISECONDS;
    const afterTomorrow = tomorrow + ONE_DAY_IN_MILLISECONDS;
    const afterAfterTomorrow = afterTomorrow + ONE_DAY_IN_MILLISECONDS;
    const dateTS: number = date.getLocalTime();

    switch (true) {
      case (yesterday <= dateTS) && (dateTS < todayTimestamp):
        return i18n.t('global.entity.yesterday');
      case (todayTimestamp <= dateTS) && (dateTS < tomorrow):
        return i18n.t('global.entity.today');
      case (tomorrow <= dateTS) && (dateTS < afterTomorrow):
        return i18n.t('global.entity.tomorrow');
      case (afterTomorrow <= dateTS) && (dateTS < afterAfterTomorrow):
        return i18n.t('global.entity.day_after_tomorrow');
      default:
        return `${date.getDate()} ${this.getHumanMonthByDate(date)}`;
    }
  },
  getHumanMonthByDate(date: Date): string | VueI18n.TranslateResult {
    return i18n.t(`global.month.${date.getMonth() + 1}.full`);
  },
  getHumanFullWeekByDate(date: SystemDate): string | VueI18n.TranslateResult {
    return i18n.t(`global.week.${date.getEuropeanDay()}.full`);
  }
};
