







































import ProductAddPopup from '@/components/ui/popups/takeAway/ProductAddPopup/index.vue';
import ProductWithIngredientsAddPopup
  from '@/components/ui/popups/takeAway/ProductWithIngredientsAddPopup/index.vue';
import DefaultImage from '@/components/ui/product/DefaultImage/index.vue';
import getPopupController from '@/lib/vuePopup';
import { PopupControllerPublicProperties } from '@/lib/vuePopup/interfaces';
import Place from '@/models/place';
import Product from '@/models/product';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';

@Component({
  name: 'TakeAwayMenuCategoryProduct',
  components: {
    DefaultImage,
  },
})
export default class TakeAwayMenuCategoryProduct extends Vue {
  @Prop({
    type: Product,
    required: true,
  })
  private product!: Product;

  private get productCount(): number {
    return this.getOrderProductsCountByProduct(this.product);
  }

  @State('selectedPlace', {
    namespace: 'place',
  })
  private selectedPlace!: Place;

  @Getter('getOrderProductsCountByProduct', {
    namespace: 'takeAway/order',
  })
  private getOrderProductsCountByProduct!: (product: Product) => number;

  private popupController: PopupControllerPublicProperties = getPopupController();

  private selectProduct() {
    if (this.product.isHaveIngredientsForSelect) {
      this.openAddProductWithIngredientsPopup();
    } else {
      this.openAddProductPopup();
    }
  }

  private openAddProductWithIngredientsPopup() {
    this.popupController.createPopup(ProductWithIngredientsAddPopup, {
      propsData: {
        product: this.product,
      },
    });
  }

  private openAddProductPopup() {
    this.popupController.createPopup(ProductAddPopup, {
      propsData: {
        product: this.product,
      },
    });
  }
}
