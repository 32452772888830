import { GroupTypes, IngredientsGroupProps } from '@/interfaces/models/ingredientGroup';
import { ProductProps } from '@/interfaces/models/product';
import IngredientsGroup from '@/models/ingredientGroup';

export default class Product {
  public category: { id: string; } | null = null;
  public cost: number = 0;
  public description: string = '';
  public groups: IngredientsGroup[] = [];
  public id: string = '';
  public image: string | null = null;
  public priority: number = 0;
  public projectProduct: any = null;
  public title: string = '';
  public weight: string = '';

  [key: string]: any;

  public constructor(productProps: ProductProps) {
    this.category = productProps.category;
    this.cost = productProps.cost;
    this.description = productProps.description;

    if (undefined !== productProps.groups) {
      this.groups = productProps.groups.map((ingredientGroupProps: IngredientsGroupProps<GroupTypes>) => {
        return new IngredientsGroup(ingredientGroupProps);
      });
    }

    this.id = productProps.id;
    this.image = productProps.image;
    this.priority = productProps.priority;
    this.projectProduct = productProps.projectProduct;
    this.title = productProps.title;
    this.weight = productProps.weight;
  }

  get isHaveIngredientsForSelect() {
    return this.isHaveOptionalIngredients || this.isHaveOneOfIngredientsGroupWithIngredients;
  }

  get isHaveOptionalIngredients(): boolean {
    return this.optionalIngredients.length > 0;
  }

  get isHaveOneOfIngredientsGroupWithIngredients(): boolean {
    return this.oneOfIngredientGroupsWithIngredients.length > 0;
  }

  get optionalIngredients() {
    if (undefined !== this.optionalIngredientsGroup) {
      return this.optionalIngredientsGroup.ingredients;
    }

    return [];
  }

  get optionalIngredientsGroup(): IngredientsGroup | undefined {
    return this.groups.find(({ isOptional }: IngredientsGroup) => isOptional);
  }

  get oneOfIngredientGroupsWithIngredients(): IngredientsGroup[] {
    return this.groups.filter(({ isOneOf, ingredients }: IngredientsGroup) => {
      return isOneOf && ingredients.length > 0;
    });
  }
}
