import { PlaceProps, PlaceSettings } from '@/interfaces/models/place';
import { TimetableProps } from '@/interfaces/models/timetable';
import Timetable from '@/models/timetable';
import { ONE_DAY_IN_SECONDS } from '@/utils/format/date';
import PlaceDate from '@/utils/placeDate';
import { AsYouType } from 'libphonenumber-js';

export default class Place {
  public address: string;
  public coordinates: number[];
  public currency: string;
  public id: string;
  public logo: string | null = null;
  public appLogo: string | null = null;
  public name: string;
  public country: string;
  public city: string;
  public phone: string | null = null;
  public email: string | null = null;
  public settings: PlaceSettings;
  public timezone: number;
  public project: any;
  public legalInformation: any;
  public isOnlineOrderWithPaymentEnabled: boolean;
  public isOnlineOrderWithoutPaymentEnabled: boolean;

  [key: string]: any;

  constructor(placeProps: PlaceProps) {
    this.address = placeProps.address;
    this.coordinates = placeProps.coordinates;
    this.currency = placeProps.currency;
    this.id = placeProps.id;
    this.logo = placeProps.logo;
    this.appLogo = placeProps.appLogo;
    this.name = placeProps.name;
    this.country = placeProps.country;
    this.city = placeProps.city;
    this.email = placeProps.email;

    if (null !== placeProps.phone) {
      this.phone = (new AsYouType).input(placeProps.phone);
    }

    this.settings = {
      ...placeProps.settings,
      timetable: placeProps.settings.timetable.map((props: TimetableProps): Timetable => new Timetable(props)),
      timetableTakeAway: placeProps.settings.timetableTakeAway.map((props: TimetableProps): Timetable => new Timetable(props)),
    };
    this.timezone = placeProps.timezone;
    this.project = placeProps.project;
    this.legalInformation = placeProps.legalInformation;
    this.isOnlineOrderWithPaymentEnabled = placeProps.isOnlineOrderWithPaymentEnabled;
    this.isOnlineOrderWithoutPaymentEnabled = placeProps.isOnlineOrderWithoutPaymentEnabled;
  }

  public isPlaceOpen(timestampInPlaceTimezone: number): boolean {
    const date = new PlaceDate(timestampInPlaceTimezone);
    const dayOfWeek = date.getEuropeanDay();
    const timetable = this.getTimetableByDayOfWeek(dayOfWeek);

    return timetable && timetable.isWorkingTime(date.getTodayTimeInSeconds());
  }

  public getTimestampWhenPlaceWillOpen(timestampInPlaceTimezone: number): number {
    const date = new PlaceDate(timestampInPlaceTimezone);
    const dayOfWeek = date.getEuropeanDay();
    const timetable = this.getTimetableByDayOfWeek(dayOfWeek);

    if (timetable.isCloseUntilTomorrow(date.getTodayTimeInSeconds())) {
      for (let day = 1; day <= 7; day++) {
        const currentDay = date.clone();
        currentDay.setHours(0, 0, 0, 0);
        currentDay.setDate(currentDay.getDate() + day);

        const currentDayOfWeek = currentDay.getEuropeanDay();
        const currentTimetable = this.getTimetableByDayOfWeek(currentDayOfWeek);

        if (currentTimetable.isCloseUntilTomorrow(currentDay.getTodayTimeInSeconds())) {
          continue;
        }

        const timeToOpen = currentTimetable.getTimeToOpen(currentDay.getTodayTimeInSeconds());
        const weekendsInSeconds = (day - 1) * ONE_DAY_IN_SECONDS;
        const endOfTodayInSeconds = timestampInPlaceTimezone - date.getTodayTimeInSeconds() + ONE_DAY_IN_SECONDS;

        return endOfTodayInSeconds + timeToOpen + weekendsInSeconds;
      }
    }

    return timestampInPlaceTimezone + timetable.getTimeToOpen(date.getTodayTimeInSeconds());
  }

  public isWorkingTillEndOfDay(timestampInPlaceTimezone: number): boolean {
    const date = new PlaceDate(timestampInPlaceTimezone);
    const dayOfWeek = date.getEuropeanDay();
    const timetable = this.getTimetableByDayOfWeek(dayOfWeek);

    return timetable.isWorkingTillEndOfDay;
  }

  public isNextDayWorkingFromStartOfDay(timestampInPlaceTimezone: number): boolean {
    const date = new PlaceDate(timestampInPlaceTimezone);
    const nextDay = date.clone().startOfNextDay();
    const nextDayOfWeek = nextDay.getEuropeanDay();
    const nextTimetable = this.getTimetableByDayOfWeek(nextDayOfWeek);

    return nextTimetable.isWorkingFromStartOfDay;
  }

  public getTimeToClose(timestampInPlaceTimezone: number): number {
    const date = new PlaceDate(timestampInPlaceTimezone);
    const nextDay = date.clone().startOfNextDay();
    const dayOfWeek = date.getEuropeanDay();
    const nextDayOfWeek = nextDay.getEuropeanDay();
    const timetable = this.getTimetableByDayOfWeek(dayOfWeek);
    const nextTimetable = this.getTimetableByDayOfWeek(nextDayOfWeek);
    const timeToClose = timetable.getTimeToClose(date.getTodayTimeInSeconds());

    if (
      !timetable.isWorkingTillEndOfDay
      || (timetable.isWorkingTillEndOfDay && !nextTimetable.isWorkingFromStartOfDay)
    ) {
      return timeToClose;
    }

    return timeToClose + nextTimetable.getTimeToClose(nextDay.getTodayTimeInSeconds());
  }

  public getTimetableByDayOfWeek(dayOfWeek: number): Timetable {
    return this.settings.isTakeAwayTBDefault
      ? this.settings.timetableTakeAway[dayOfWeek]
      : this.settings.timetable[dayOfWeek];
  }
}
