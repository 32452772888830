import { PopupStorageInterface, PopupStorageItem } from './interfaces';
import HaveNotPopupsInPopupStorage from '@/lib/vuePopup/exceptions/haveNotPopupsInPopupStorage';

export default function PopupStorage(): PopupStorageInterface {
  const popups: PopupStorageItem[] = [];

  return {
    addPopupByPopupHTMLElement,
    removePopupByPopupHTMLElement,
    getAllPopups,
    getLastPopupStorageItem,
    hasOnlyOnePopupStorageItem,
    havePopupStorageItems,
  };

  function addPopupByPopupHTMLElement(popupElement: HTMLElement) {
    const popup = createPopupStorageItemByPopupHTMLElement(popupElement);

    popups.push(popup);
  }

  function removePopupByPopupHTMLElement(popupElement: HTMLElement) {
    const popupIndex = popups.findIndex(({ element }: PopupStorageItem) => {
      return element === popupElement;
    });

    if (-1 !== popupIndex) {
      popups.splice(popupIndex, 1);
    }
  }

  function createPopupStorageItemByPopupHTMLElement(popupElement: HTMLElement): PopupStorageItem {
    return {
      element: popupElement,
      isRolledUp: false
    };
  }

  function getAllPopups(): PopupStorageItem[] {
    return popups;
  }

  function getLastPopupStorageItem(): PopupStorageItem {
    if (popups.length === 0) {
      throw new HaveNotPopupsInPopupStorage();
    }

    return popups[popups.length - 1];
  }

  function hasOnlyOnePopupStorageItem(): boolean {
    return popups.length === 1;
  }

  function havePopupStorageItems(): boolean {
    return popups.length > 0;
  }
};
