












































import CreditCard from '@/components/ui/creditCard/CreditCard.vue';
import HiddenCreditCard from '@/models/creditCard/hiddenCreditCard';
import { Component, Prop, Vue } from 'vue-property-decorator';
import PopupWrapper from "@/components/ui/PopupWrapper/index.vue";

@Component({
  name: 'ConfirmRemoveCardPopup',
  components: {
    CreditCard,
    PopupWrapper,
  },
})
export default class ConfirmRemoveCardPopup extends Vue {
  @Prop({
    type: HiddenCreditCard,
    required: true,
  })
  private creditCard!: HiddenCreditCard;

  private confirm() {
    this.$emit('confirm');

    this.close();
  }

  close() {
    this.$destroy();
  }
}
