





























































import { Component, Vue, Prop } from 'vue-property-decorator';
import PopupWrapper from '@/components/ui/PopupWrapper/index.vue';

@Component({
  name: 'QRCodeViewPopup',
  components: {
    PopupWrapper
  }
})
export default class QRCodeViewPopup extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  private order!: any;

  private close() {
    this.$destroy();
  }
}
