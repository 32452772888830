export const APP_EVENTS = Object.freeze({
  MENU_FETCHED: 'menu:fetched',
  CART_PANEL_DISPLAYED: 'cart.panel:displayed',
  CART_PANEL_CLOSED: 'cart.panel:closed',
  USER_FETCHED: 'user:fetched',
});

const DEFAULT_EVENT_OPTIONS = {
  cancelable: false,
  bubbles: false
};

export default {
  dispatchEvent(
    eventName: string,
    target: Element | Document = document,
    eventOptions: CustomEventInit = DEFAULT_EVENT_OPTIONS
  ) {
    const event = new CustomEvent(eventName, eventOptions);

    target.dispatchEvent(event);
  }
};
