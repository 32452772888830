















































import PlaceCommentPopup from '@/components/ui/popups/place/PlaceCommentPopup/index.vue';
import getPopupController from '@/lib/vuePopup';
import { PopupControllerPublicProperties } from '@/lib/vuePopup/interfaces';
import { Component, Vue } from 'vue-property-decorator';
import PopupWrapper from '@/components/ui/PopupWrapper/index.vue';
import PlaceLogo from '@/components/ui/place/PlaceLogo/index.vue';
import PlaceList from './components/PlaceList.vue';
import PlacesMap from './components/PlacesMap.vue';
import { State } from 'vuex-class';
import Place from '@/models/place';

const TABS = Object.freeze({
  LIST: 'LIST',
  MAP: 'MAP',
});

@Component({
  name: 'SelectPlacePopup',
  components: {
    PopupWrapper,
    PlaceLogo,
    PlaceList,
    PlacesMap,
  }
})
export default class SelectPlacePopup extends Vue {
  private TABS = TABS;
  private selectedTab = TABS.LIST;
  private popupController: PopupControllerPublicProperties = getPopupController();

  private get defaultPlaceId(): string | null {
    for (const place of this.places) {
      if (place.id) {
        return place.id;
      }
    }

    return null;
  }

  @State('places', {
    namespace: 'place',
  })
  private places!: Place[];

  @State('projectId')
  projectId!: string;

  private selectTab(selectedTab: string) {
    this.selectedTab = selectedTab;
  }

  private onPlaceSelected(place: Place) {
    if (place.settings.widget.additionalInformationEnabled) {
      this.popupController.createPopup(PlaceCommentPopup, {
        propsData: {
          comment: place.settings.widget.additionalInformation,
        },
      });
    }

    this.close();
  }

  private close() {
    this.$destroy();
  }
}
