import Ingredient from '@/models/ingredient';
import numberUtils from '@/utils/number.utils';

export default class OrderProductIngredient {
  count: number;
  ingredient: Ingredient;

  public constructor(ingredient: Ingredient, count: number = 1) {
    this.ingredient = ingredient;
    this.count = count;
  }

  public static createByRequestPayload(props: any): OrderProductIngredient {
    const ingredient = new Ingredient(props.ingredient);

    return new OrderProductIngredient(ingredient, props.count);
  }

  get cost() {
    return numberUtils.correctFloatNumber(this.ingredient.fullCost);
  }

  get fullCost() {
    return numberUtils.correctFloatNumber(this.count * this.ingredient.fullCost);
  }

  public static getOrderProductIngredientFinderByIngredient(neededIngredient: Ingredient) {
    return ({ ingredient }: OrderProductIngredient) => ingredient.fullHash === neededIngredient.fullHash;
  }

  public get payload() {
    return {
      count: this.count,
      id: this.ingredient.id,
      ingredientCount: this.ingredient.count,
      cost: this.ingredient.cost,
      unitCount: this.ingredient.unitCount,
    };
  }
}
