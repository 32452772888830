export default new class VueSocketIOLogger {
  private isDebug: boolean = process.env.NODE_ENV === 'development';

  info(text: string, data: string = '') {
    if (this.isDebug) {
      window.console.info(`%cJustReserveMe: %c${text}`, 'color: green; font-weight: 600', 'color: #666', data);
    }

  }

  error(text: string, ...args: any[]) {
    if (this.isDebug) {
      window.console.error(`%cJustReserveMe: %c${text}`, 'color: red; font-weight: 600', 'color: #666', ...args);
    }

  }

  warn(text: string, ...args: any[]) {
    if (this.isDebug) {
      window.console.warn(`%cJustReserveMe: %c${text}`, 'color: #ffaf00; font-weight: 600', 'color: #666', ...args);
    }
  }
};
