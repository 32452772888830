

















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'BaseAsyncButton'
})
export default class BaseAsyncButton extends Vue {
  @Prop({
    type: Function,
    required: true
  })
  asyncFunction!: Function;

  isLoading: boolean = false;

  async handleClick() {
    if (!this.isLoading) {
      this.isLoading = true;

      await this.asyncFunction();

      this.isLoading = false;
    }
  }
}
