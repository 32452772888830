import camelCase from 'lodash.camelcase';
import Vue from 'vue';

const requireModule = require.context('./', false, /\.\/(?!index)([\w\-\.]+)(\.ts)$/);

requireModule
  .keys()
  .forEach(importDirectiveFromFileByFileName);

function importDirectiveFromFileByFileName(fileName: string) {
  const directiveName = prepareFileNameForImport(fileName);
  const module = requireModule(fileName);

  Vue.directive(directiveName, module.default || module);
}

function prepareFileNameForImport(fileName: string) {
  return camelCase(
    fileName.replace(/(\.\/|\.ts)/g, '')
  );
}
