import Type, { CREDIT_CARD_TYPE } from '@/models/creditCard/type';

export default new Type(
  3,
  16,
  /^220[0-4]/,
  /(\d{1,4})(\d{1,4})?(\d{1,4})?(\d{1,4})?(\d{1,3})?/,
  true,
  CREDIT_CARD_TYPE.Mir,
  /^220[0-4]\d{12}$/,
);
