









































































import Place from '@/models/place';
import PlaceDate from '@/utils/placeDate';
import { Component, Vue } from 'vue-property-decorator';
import { State, Getter } from 'vuex-class';

@Component({
  name: 'PlaceAlreadyClosedNotice',
})
export default class PlaceAlreadyClosedNotice extends Vue {
  private get openDate(): string {
    if (this.selectedPlace === null) {
      return '';
    }

    const date = new PlaceDate(this.selectedPlace.getTimestampWhenPlaceWillOpen(this.nowInSelectedPlaceInSeconds));

    return `${date.getHumanDateString()} в ${date.getHumanTime()}`.toLowerCase();
  }

  @State('selectedPlace', {
    namespace: 'place'
  })
  private selectedPlace!: Place;

  @Getter('nowInSelectedPlaceInSeconds', {
    namespace: 'timer',
  })
  private nowInSelectedPlaceInSeconds!: number;

  private mounted(): void {
    if (this.$el && this.$el instanceof HTMLElement) {
      document.body.appendChild(this.$el);
    }
  }

  private close() {
    this.$emit('close');
  }

  private beforeDestroy(): void {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
  }
}
