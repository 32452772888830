


















import IngredientsGroup from '@/models/ingredientGroup';
import OneOfGroupIngredient from './OneOfGroupIngredient.vue';
import Ingredient from '@/models/ingredient';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'OneOfGroup',
  components: {
    OneOfGroupIngredient
  }
})
export default class OneOfGroup extends Vue {
  @Prop({
    type: IngredientsGroup,
    required: true,
    validator(oneOfIngredientsGroup: IngredientsGroup): boolean {
      return oneOfIngredientsGroup.isOneOf;
    }
  })
  private oneOfIngredientsGroup!: IngredientsGroup;

  private selectedIngredient: Ingredient | undefined = this.oneOfIngredientsGroup.ingredients[0];

  @Watch('selectedIngredient', {
    immediate: true
  })
  onSelectedIngredientChange(selectedIngredient: Ingredient, oldSelectedIngredient: Ingredient) {
    this.$emit(
      'replaceSelectedIngredients',
      {
        selectedIngredient,
        oldSelectedIngredient,
        group: this.oneOfIngredientsGroup
      }
    );
  }

  private selectIngredient(ingredient: Ingredient) {
    this.selectedIngredient = ingredient;
  }
}
