import {BuyXGetYProductProps} from '@/interfaces/models/buyXGetYProduct';

export default class BuyXGetYProduct {
  public id: string;
  public cost: number;
  public description: string;
  public title: string;
  public weight: string;
  public image?: string;

  [key: string]: any;

  public constructor(
    id: string,
    cost: number,
    description: string,
    title: string,
    weight: string,
    image?: string,
  ) {
    this.id = id;
    this.cost = cost;
    this.description = description;
    this.title = title;
    this.weight = weight;
    this.image = image;
  }

  public static createFromRequest(productProps: BuyXGetYProductProps): BuyXGetYProduct {
    return new BuyXGetYProduct(
      productProps.id,
      productProps.cost,
      productProps.description,
      productProps.title,
      productProps.weight,
      productProps.image,
    );
  }
}
