













import OrderProductListItem from './OrderProductListItem.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'OrderProductList',
  components: {
    OrderProductListItem
  }
})
export default class OrderProductList extends Vue {
  @Prop({
    type: Array,
    required: true
  })
  orderProducts!: any[];

  @Prop({
    type: String,
    required: true
  })
  currency!: string;
}
