import axiosHelper from '@/helpers/axiosHelper';
import BuyXGetY from '@/models/buyXGetY';
import {BuyXGetYProps} from '@/interfaces/models/promotion';

export default axiosHelper.mergeAxiosResponseTransformerWithDefaults(
  (response: BuyXGetYProps[]): BuyXGetY[] => response
    .map(BuyXGetY.createFromRequest)
    .sort((firstPromotion: BuyXGetY, secondPromotion: BuyXGetY): number => {
      if (firstPromotion.clientProgressReceivedCount === secondPromotion.clientProgressReceivedCount) {
        return firstPromotion.clientProgressCount / firstPromotion.necessaryCount > secondPromotion.clientProgressCount / secondPromotion.necessaryCount ? -1 : 1
      }

      return firstPromotion.clientProgressReceivedCount > secondPromotion.clientProgressReceivedCount ? -1 : 1;
    })
);
