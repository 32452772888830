

































































































































import PopupWrapper from '@/components/ui/PopupWrapper/index.vue';
import DefaultImage from '@/components/ui/product/DefaultImage/index.vue';
import Ingredient from '@/models/ingredient';
import IngredientsGroup from '@/models/ingredientGroup';
import OrderProduct from '@/models/orderProduct';
import Place from '@/models/place';
import TakeAway from '@/models/takeAway';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { State, Mutation } from 'vuex-class';
import OneOfGroup from './components/OneOfGroup.vue';
import OptionalGroup from './components/OptionalGroup.vue';
import BuyXGetY from '@/models/buyXGetY';

@Component({
  name: 'PromotionOrderProductWithIngredientsEditPopup',
  components: {
    DefaultImage,
    OneOfGroup,
    OptionalGroup,
    PopupWrapper,
  },
})
export default class PromotionOrderProductWithIngredientsEditPopup extends Vue {
  @Prop({
    type: OrderProduct,
    required: true,
  })
  private initOrderProduct!: OrderProduct;

  @Prop({
    type: String,
    required: true,
  })
  private promotionId!: string;

  private orderProduct: OrderProduct = cloneDeep(this.initOrderProduct);

  private get canIncrementIngredientCount(): boolean {
    return this.selectedPlace.settings.widget.maxIngredientCount > this.orderProduct.ingredientsCount;
  }

  private get isProductWasChange() {
    return !isEqual(this.initOrderProduct, this.orderProduct);
  }

  private get maxCount() {
    const promotion = this.promotions.find((promotion: BuyXGetY): boolean => promotion.id === this.promotionId);

    if (undefined === promotion) {
      return 0;
    }

    return promotion.clientProgressReceivedCount;
  }

  @State('selectedPlace', {
    namespace: 'place',
  })
  private selectedPlace!: Place;

  @State('order', {
    namespace: 'takeAway/order',
  })
  private order!: TakeAway;

  @State('promotions', {
    namespace: 'promotion',
  })
  private promotions!: BuyXGetY[];

  private incrementOrderProductIngredientCountByIngredient(ingredient: Ingredient) {
    if (this.canIncrementIngredientCount) {
      this.orderProduct.incrementOrderProductIngredientCountByIngredient(ingredient);
    }
  }

  private decrementOrderProductIngredientCountByIngredient(ingredient: Ingredient) {
    this.orderProduct.decrementOrderProductIngredientCountByIngredient(ingredient);
  }

  private selectOneOfIngredient(
    payload: { selectedIngredient: Ingredient, oldSelectedIngredient: Ingredient, group: IngredientsGroup },
  ) {
    this.orderProduct.selectOneOfIngredient(payload);
  }

  private changeOrderProductInOrder() {
    if (this.isProductWasChange) {
      this.changePromotionOrderProductInOrderAction({
        promotionId: this.promotionId,
        orderProduct: this.orderProduct,
      });
      this.close();
    }
  }

  private incrementOrderProductCount() {
    if (this.orderProduct.count < this.maxCount) {
      this.orderProduct.increment();
    }
  }

  private decrementOrderProductCount() {
    this.orderProduct.decrement();
  }

  private close() {
    this.$destroy();
  }

  @Mutation('changePromotionOrderProductInOrder', {
    namespace: 'takeAway/order',
  })
  changePromotionOrderProductInOrderAction!: ({ promotionId, orderProduct }: any) => void;
}
