import apiClient from '@/api/axiosClient';
import getProjectTransformer from '@/api/responseTransformers/project/project.transformer';
import { AxiosResponse } from 'axios';
import { ProjectPropsResponse } from '@/interfaces/api/project';
import getClientApiRequestTransformer from '@/api/requestTransformers/clientApi.transformer';
import getSocketIdRequestTransformer from '@/api/requestTransformers/socketId.transformer';

export default {
  async fetchProjectPropsByProjectId(projectId: string): Promise<ProjectPropsResponse> {
    return apiClient.get(`/place/list/${projectId}`, {
      transformRequest: [
        ...getClientApiRequestTransformer,
        ...getSocketIdRequestTransformer,
      ],
      transformResponse: getProjectTransformer,
    })
      .then(({ data }: AxiosResponse<ProjectPropsResponse>) => data);
  }
};
