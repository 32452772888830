import { Component, Prop, Vue } from 'vue-property-decorator';
import { POSITIONS } from '@/consts';

type styleObject = {
  width: string | null
  top: string | null
  left: string | null
  right: string | null
  bottom: string | null
  position: string
};

@Component({})
export default class AbstractDropdownContent extends Vue {
  @Prop({
    type: String,
    default: POSITIONS.BOTTOM_LEFT
  })
  displayPosition!: string;

  @Prop({
    type: Boolean,
    default: true
  })
  isFullWidth!: boolean;

  @Prop({
    type: String
  })
  selector!: string;

  @Prop({
    type: MouseEvent
  })
  currentEvent!: MouseEvent;

  dropDownLayer: HTMLBodyElement = <HTMLBodyElement> document.body;

  styleObject: styleObject = {
    width: null,
    top: null,
    left: null,
    right: null,
    bottom: null,
    position: 'absolute'
  };

  // @ts-ignore
  resizeObserver: ResizeObserver = new ResizeObserver(this.$_abstractDropdownContent_calculatePosition);

  mounted() {
    this.resizeObserver.observe(this.$el);

    this.dropDownLayer.appendChild(this.$el);

    this.$_abstractDropdownContent_calculatePosition();
  }

  beforeDestroy() {
    this.resizeObserver.unobserve(this.$el);

    if (this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
  }

  $_abstractDropdownContent_calculatePosition() {
    const parentElement = this.selector
      ? this.$parent.$el.querySelector(this.selector)
      : this.$parent.$el;

    if (parentElement) {
      const { width, bottom, left, right, top } = parentElement.getBoundingClientRect();

      this.styleObject.width = this.isFullWidth ? `${width}px` : null;

      switch (this.displayPosition) {
        case POSITIONS.BOTTOM_LEFT:
          this.$_abstractDropdownContent_displayBottomLeft(bottom, left);

          break;
        case POSITIONS.BOTTOM_RIGHT:
          this.$_abstractDropdownContent_displayBottomRight(bottom, right);

          break;
        case POSITIONS.TOP_LEFT:
          this.$_abstractDropdownContent_displayTopLeft(top, left);

          break;
        case POSITIONS.TOP_RIGHT:
          this.$_abstractDropdownContent_displayTopRight(top, right);

          break;
        case POSITIONS.TOP_CENTER:
          this.$_abstractDropdownContent_displayTopCenter(top, left, width);

          break;
        case POSITIONS.BOTTOM_CENTER:
          this.$_abstractDropdownContent_displayBottomCenter(bottom, left, width);

          break;
        case POSITIONS.CURSOR:
          this.$_abstractDropdownContent_displayOnCursor();

          break;
        case POSITIONS.SCREEN_CENTER:
          this.$_abstractDropdownContent_displayOnScreenCenter();

          break;
        default:
          this.$_abstractDropdownContent_displayBottomLeft(bottom, left);
      }
    }
  }

  $_abstractDropdownContent_displayBottomLeft(bottom: number, left: number): void {
    this.styleObject.top = this.$_abstractDropdownContent_getTopByParentBottom(bottom);
    this.styleObject.left = this.$_abstractDropdownContent_getLeftByParentLeft(left);
    this.styleObject.bottom = null;
    this.styleObject.right = null;
  }

  $_abstractDropdownContent_displayBottomRight(bottom: number, right: number): void {
    this.styleObject.top = this.$_abstractDropdownContent_getTopByParentBottom(bottom);
    this.styleObject.right = this.$_abstractDropdownContent_getRightByParentRight(right);
    this.styleObject.bottom = null;
    this.styleObject.left = null;
  }

  $_abstractDropdownContent_displayTopLeft(top: number, left: number): void {
    this.styleObject.bottom = this.$_abstractDropdownContent_getBottomByParentTop(top);
    this.styleObject.left = this.$_abstractDropdownContent_getLeftByParentLeft(left);
    this.styleObject.top = null;
    this.styleObject.right = null;
  }

  $_abstractDropdownContent_displayTopCenter(top: number, left: number, width: number): void {
    this.styleObject.bottom = this.$_abstractDropdownContent_getBottomByParentTop(top);
    this.styleObject.left = this.$_abstractDropdownContent_getLeftByParentLeftAndWidth(left, width);
    this.styleObject.top = null;
    this.styleObject.right = null;
  }

  $_abstractDropdownContent_displayBottomCenter(bottom: number, left: number, width: number): void {
    this.styleObject.top = this.$_abstractDropdownContent_getTopByParentBottom(bottom);
    this.styleObject.left = this.$_abstractDropdownContent_getLeftByParentLeftAndWidth(left, width);
    this.styleObject.bottom = null;
    this.styleObject.right = null;
  }

  $_abstractDropdownContent_displayTopRight(top: number, right: number): void {
    this.styleObject.bottom = this.$_abstractDropdownContent_getBottomByParentTop(top);
    this.styleObject.right = this.$_abstractDropdownContent_getRightByParentRight(right);
    this.styleObject.top = null;
    this.styleObject.left = null;
  }

  $_abstractDropdownContent_displayOnCursor(): void {
    this.styleObject.top = `${this.currentEvent.pageY}px`;
    this.styleObject.left = `${this.currentEvent.pageX}px`;
    this.styleObject.bottom = null;
    this.styleObject.right = null;
  }

  $_abstractDropdownContent_displayOnScreenCenter(): void {
    const element: HTMLElement = <HTMLElement> this.$el;

    this.styleObject.top = `${(window.innerHeight - element.offsetHeight) / 2}px`;
    this.styleObject.left = `${(window.innerWidth - element.offsetWidth) / 2}px`;
    this.styleObject.bottom = null;
    this.styleObject.right = null;
  }

  $_abstractDropdownContent_getLeftByParentLeft(parentLeft: number): string {
    const element: HTMLElement = <HTMLElement> this.$el;

    return window.innerWidth - parentLeft >= element.offsetWidth
      ? `${parentLeft}px`
      : `${window.innerWidth - element.offsetWidth}px`;
  }

  $_abstractDropdownContent_getLeftByParentLeftAndWidth(parentLeft: number, parentWidth: number): string {
    const element: HTMLElement = <HTMLElement> this.$el;

    return window.innerWidth - parentLeft >= element.offsetWidth
      ? `${parentLeft + (parentWidth - element.offsetWidth) / 2}px`
      : `${window.innerWidth - element.offsetWidth}px`;
  }

  $_abstractDropdownContent_getRightByParentRight(parentRight: number): string {
    const element: HTMLElement = <HTMLElement> this.$el;

    return parentRight >= element.offsetWidth
      ? `${window.innerWidth - parentRight}px`
      : `${window.innerWidth - element.offsetWidth}px`;
  }

  $_abstractDropdownContent_getTopByParentBottom(parentBottom: number): string {
    const element: HTMLElement = <HTMLElement> this.$el;

    return window.innerHeight - parentBottom >= element.offsetHeight
      ? `${parentBottom}px`
      : `${window.innerHeight - element.offsetHeight}px`;
  }

  $_abstractDropdownContent_getBottomByParentTop(parentTop: number): string {
    const element: HTMLElement = <HTMLElement> this.$el;

    return parentTop >= element.offsetHeight
      ? `${window.innerHeight - parentTop}px`
      : `${window.innerHeight - element.offsetHeight}px`;
  }
}
