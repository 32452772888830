import Vue from 'vue';
import stringFormatter from '@/utils/format/string';

const requireComponent = require.context('./', false, /Base[A-Z]\w+\.(vue|js)$/);

requireComponent.keys().forEach((fileName: string) => {
  const componentConfig = requireComponent(fileName);
  const componentName = stringFormatter.pascalCase(
    fileName.replace(/^\.\/(.*)\.\w+$/, '$1')
  );

  Vue.component(
    componentName,
    componentConfig.default || componentConfig
  );
});
