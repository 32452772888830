import Vue from 'vue';
import io from 'socket.io-client';
import store from '@/store';
import camelCase from 'lodash.camelcase';
import SocketIoPlugin from '@/lib/socket.io-plugin/index';

Vue.use(new SocketIoPlugin({
  vuex: {
    store,
    actionPrefix: '',
    mutationPrefix: ''
  },
  connection: io(process.env.VUE_APP_NODE_URL),
  eventToMutationTransformer: formatEventNameToMutation,
  eventToActionTransformer: formatEventNameToAction
}));

export function formatEventNameToAction(eventName: string) {
  return camelCase(`socket_${eventName}`);
}

export function formatEventNameToMutation(eventName: string) {
  return camelCase(`socket_${eventName}`);
}
