









import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'BaseSlider'
})
export default class BaseSlider extends Vue {
  @Prop([
    String,
    Number,
  ])
  private value!: string | number;

  @Prop({
    type: Number,
    default: 1
  })
  private minValue!: number;

  @Prop({
    type: Number,
    default: 10
  })
  private maxValue!: number;

  @Prop({
    type: Number,
    default: 1
  })
  private steep!: number;

  @Prop({
    type: Number,
    default: 1
  })
  private zoom!: number;

  private oldValue: number | string = 0;

  private get intValue() {
    if (typeof this.value === 'string') {
      return parseFloat(this.value);
    }

    return this.value;
  }

  private get numberOfValues() {
    return (this.maxValue - this.minValue) / this.steep;
  }

  @Watch('value')
  private onValueChanged(value: number | string, oldValue: number | string) {
    this.oldValue = oldValue;
  }

  private mounted() {
    const element: HTMLElement = <HTMLElement> this.$el;

    element.addEventListener('touchstart', <any> this.handleChange, false);
    element.addEventListener('touchmove', <any> this.handleChange, false);
  }

  private handleMouseDown(e: any) {
    this.handleChange(e, true);
    window.addEventListener('mousemove', this.handleChangeWithPrevent);
    window.addEventListener('mouseup', this.handleMouseUp);
  }

  private unbindEventListeners() {
    window.removeEventListener('mousemove', this.handleChangeWithPrevent);
    window.removeEventListener('mouseup', this.handleMouseUp);
  }

  private handleChangeWithPrevent(e: any) {
    this.handleChange(e, false);
  }

  private handleChange(e: any, skip: boolean) {
    !skip && e.preventDefault();

    const container: HTMLElement = <HTMLElement> this.$refs.container;
    const containerWidth = container.clientWidth * this.zoom;
    const xOffset = container.getBoundingClientRect().left * this.zoom + window.scrollX;
    const pageX = e.pageX || (e.touches ? e.touches[0].pageX : 0);
    const left = pageX - xOffset;
    const percent = left * 100 / containerWidth;
    const h = this.numberOfValues * percent * this.steep / 100 + this.minValue;

    let value = parseFloat((Math.round(h / this.steep) * this.steep).toFixed(2));

    if (value < this.minValue) {
      value = this.minValue;
    } else if (value > this.maxValue) {
      value = this.maxValue;
    }

    if (this.intValue !== value) {
      this.$emit('input', value);
    }
  }

  private handleMouseUp() {
    this.unbindEventListeners();
  }
}
