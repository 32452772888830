import apiClient from '@/api/axiosClient';
import { AxiosResponse } from 'axios';
import getClientApiRequestTransformer from '@/api/requestTransformers/clientApi.transformer';
import getSocketIdRequestTransformer from '@/api/requestTransformers/socketId.transformer';

export default {
  async getLoginCodeByUserPhone(payload: any): Promise<any[]> {
    return apiClient.post(
      '/login/sms',
      payload,
      {
        transformRequest: getSocketIdRequestTransformer,
      },
    )
      .then(({ data }: AxiosResponse<any[]>) => data);
  },
  async login(payload: any): Promise<any> {
    return apiClient.post(
      '/login_check',
      payload,
      {
        transformRequest: getSocketIdRequestTransformer,
      },
    )
      .then(({ data }: AxiosResponse<any>) => {
        window.localStorage.setItem('jrm.accessToken', data.token);

        return {
          client: data.client,
          orders: data.orders,
          creditCards: data.creditCards
        };
      });
  },
  async getRegisterCodeByPhone(orderId: string, payload: any): Promise<any[]> {
    return apiClient.post(
      `/order/request-add-client/${orderId}`,
      payload,
      {
        transformRequest: getSocketIdRequestTransformer,
      },
    )
      .then(({ data }: AxiosResponse<any[]>) => data);
  },
  async register(orderId:string, payload: any): Promise<any> {
    return apiClient.post(
      `/order/add-client/${orderId}`,
      payload,
      {
        transformRequest: getSocketIdRequestTransformer,
      },
    )
      .then(({ data }: AxiosResponse<any>) => {
        window.localStorage.setItem('jrm.accessToken', data.token);

        return {
          client: data.client,
          orders: data.orders,
          creditCards: data.creditCards
        };
      });
  },
  async tryToAuthUserByCookie(): Promise<any> {
    return apiClient.get('/client/check_token', {
      transformRequest: [
        ...getClientApiRequestTransformer,
        ...getSocketIdRequestTransformer,
      ]
    })
      .then(({ data }: AxiosResponse<any[]>) => data)
      .catch((error): void => {
        if (401 === error.response.status) {
          window.localStorage.removeItem('jrm.accessToken');
        }
      });
  },
  async removeCreditCard(id: string): Promise<any> {
    return apiClient.delete(`/credit-cards/${id}`, {
      transformRequest: [
        ...getClientApiRequestTransformer,
        ...getSocketIdRequestTransformer,
      ]
    });
  }
};
