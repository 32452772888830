import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '@/store';
import PlaceDate from '@/utils/placeDate';
import SystemDate from '@/utils/systemDate';

const namespaced: boolean = true;

export interface TimerState {
  utcNowInSeconds: number,
  today: SystemDate,
  isTimerStarted: boolean
}

const state: TimerState = {
  utcNowInSeconds: 0,
  today: new SystemDate().getStartOfDay(),
  isTimerStarted: false
};

const getters: GetterTree<TimerState, RootState> = {
  nowDate(state, getters): PlaceDate {
    return new PlaceDate(getters.nowInSelectedPlaceInSeconds);
  },
  nowInSelectedPlaceInSeconds(state, getters, rootState: any) {
    if (rootState.place.selectedPlace !== null) {
      return state.utcNowInSeconds + rootState.place.selectedPlace.timezone;
    }

    return state.utcNowInSeconds;
  },
  currentYear(state, getters) {
    return getters.nowDate.getFullYear();
  }
};

const actions: ActionTree<TimerState, RootState> = {
  startTimer({ commit, getters, state }: ActionContext<TimerState, RootState>, utcNowInSeconds: number) {
    if (!state.isTimerStarted) {
      commit('setIsTimerStarted', true);
      commit('setNow', utcNowInSeconds);

      setInterval(() => {
        commit('addOneSecondToNow');

        const now = new PlaceDate(getters.nowInSelectedPlaceInSeconds);

        if (!now.isSameDays(state.today)) {
          commit('setToday', now.getStartOfDay());
        }
      }, 1000);
    }
  }
};

const mutations: MutationTree<TimerState> = {
  addOneSecondToNow(state: TimerState) {
    state.utcNowInSeconds += 1;
  },
  setIsTimerStarted(state: TimerState, isTimerStarted: boolean) {
    state.isTimerStarted = isTimerStarted;
  },
  setNow(state: TimerState, utcNowInSeconds: number) {
    state.utcNowInSeconds = utcNowInSeconds;
  },
  setToday(state: TimerState, today: SystemDate) {
    state.today = today;
  }
};

export default {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
