import Type, { CREDIT_CARD_TYPE } from '@/models/creditCard/type';

export default new Type(
  3,
  [12, 19],
  /^(5(018|0[23]|[68])|6[37]|60111|60115|60117([56]|7[56])|60118[0-5]|64[0-3]|66)/,
  /(\d{1,4})(\d{1,4})?(\d{1,4})?(\d{1,4})?(\d{1,3})?/,
  true,
  CREDIT_CARD_TYPE.Maestro,
  /^(?:5[06789]\d\d|(?!6011[0234])(?!60117[4789])(?!60118[6789])(?!60119)(?!64[456789])(?!65)6\d{3})\d{8,15}$/,
);
