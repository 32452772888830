export enum CREDIT_CARD_TYPE {
  Visa = 'visa',
  Master = 'master',
  Maestro = 'maestro',
  Belkart = 'belkart',
  Mir = 'mir',
}

export default class Type {
  private readonly _cvcLength: number;
  private readonly _digits: number | number[];
  private readonly _eagerPattern: RegExp;
  private readonly _groupPattern: RegExp;
  private readonly _luhn: boolean;
  private readonly _type: CREDIT_CARD_TYPE;
  private readonly _pattern: RegExp;

  public constructor(
    cvcLength: number,
    digits: number | number[],
    eagerPattern: RegExp,
    groupPattern: RegExp,
    luhn: boolean,
    type: CREDIT_CARD_TYPE,
    pattern: RegExp,
  ) {
    this._cvcLength = cvcLength;
    this._digits = digits;
    this._eagerPattern = eagerPattern;
    this._groupPattern = groupPattern;
    this._luhn = luhn;
    this._type = type;
    this._pattern = pattern;
  }

  public get isVisa(): boolean {
    return CREDIT_CARD_TYPE.Visa === this._type;
  }

  public get isMastercard(): boolean {
    return CREDIT_CARD_TYPE.Master === this._type;
  }

  public get isMaestro(): boolean {
    return CREDIT_CARD_TYPE.Maestro === this._type;
  }

  public get isBelcard(): boolean {
    return CREDIT_CARD_TYPE.Belkart === this._type;
  }

  public get isMir(): boolean {
    return CREDIT_CARD_TYPE.Mir === this._type;
  }

  public get cvcLength(): number {
    return this._cvcLength;
  }

  public get digits(): number | number[] {
    return this._digits;
  }

  public get eagerPattern(): RegExp {
    return this._eagerPattern;
  }

  public get groupPattern(): RegExp {
    return this._groupPattern;
  }

  public get luhn(): boolean {
    return this._luhn;
  }

  public get type(): string {
    return this._type;
  }

  public get pattern(): RegExp {
    return this._pattern;
  }

  public group(number: string): string[] {
    return (number.match(this._groupPattern) || [])
      .slice(1)
      .filter(Boolean);
  }

  public test(number: string, eager: boolean = false): boolean {
    const pattern = eager ? this._eagerPattern : this._pattern;

    return pattern.test(number);
  }
}
