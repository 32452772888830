




































import { Component, Prop, Vue } from 'vue-property-decorator';
import PopupWrapper from "@/components/ui/PopupWrapper/index.vue";

@Component({
  name: 'ConfirmationModal',
  components: {
    PopupWrapper,
  },
})
export default class ConfirmationModal extends Vue {
  @Prop({
    type: String,
    required: true
  })
  question!: string;

  @Prop({
    type: String,
    default: null
  })
  title!: string;

  @Prop({
    type: String,
    default: null
  })
  preview!: string;

  @Prop({
    type: String,
    default: null
  })
  description!: string;

  @Prop({
    type: String,
    default: 'Удалить карту'
  })
  confirmButtonName!: string;

  @Prop({
    type: Boolean,
    default: false
  })
  isCommonConfirmation!: boolean;

  private confirm() {
    this.$emit('confirm');

    this.close();
  }

  close() {
    this.$destroy();
  }
}
