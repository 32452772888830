import Type from '@/models/creditCard/type';

export default class HiddenCreditCard {
  private readonly _id: string;
  private readonly _hiddenNumber: string;
  private readonly _type: Type;
  private readonly _holderName: string;
  private readonly _month: number;
  private readonly _year: number;

  constructor(
    id: string,
    hiddenNumber: string,
    type: Type,
    holderName: string,
    month: number,
    year: number,
  ) {
    this._id = id;
    this._hiddenNumber = hiddenNumber;
    this._type = type;
    this._holderName = holderName;
    this._month = month;
    this._year = year;
  }

  public get id(): string {
    return this._id;
  }

  public get hiddenNumber(): string {
    return this._hiddenNumber;
  }

  public get type(): Type {
    return this._type;
  }

  public get holderName(): string {
    return this._holderName;
  }

  public get expirationDate(): string {
    return `${this._month}/${this._year}`;
  }

  public get month(): number {
    return this._month;
  }

  public get year(): number {
    return this._year;
  }
}
