import { PopupRendererInterface, PopupStorageInterface } from './interfaces';
import { WIDGET_CONTAINER_ID } from '@/main';

export default function PopupRenderer(popupStorage: PopupStorageInterface): PopupRendererInterface {
  return {
    renderPopupByPopupHTMLElement,
    removePopupByPopupHTMLElement
  };

  function renderPopupByPopupHTMLElement(popupElement: HTMLElement): void {
    movePopupToTheEndOfBody(popupElement);

    popupStorage.addPopupByPopupHTMLElement(popupElement);

    blurLastPopupOrWidgetContainer();

    popupElement.focus();
  }

  function removePopupByPopupHTMLElement(popupElement: HTMLElement): void {
    if (popupElement.parentNode) {
      popupElement.parentNode.removeChild(popupElement);
    }

    popupStorage.removePopupByPopupHTMLElement(popupElement);

    unblurLastPopupOrWidgetContainer();
    focusToLastPopup();
  }

  function movePopupToTheEndOfBody(popupElement: HTMLElement): void {
    document.body.appendChild(popupElement);
  }

  function blurLastPopupOrWidgetContainer(): void {
    makeOnlyTheLastPopupWithTheActivePopupClass();

    if (popupStorage.hasOnlyOnePopupStorageItem()) {
      // blurWidgetContainer();
      addClassThatPopupIsOpen();
    } else {
      // try {
        // tryToBlurLastPopup();
      // } catch (error) {
      // }
    }
  }

  function unblurLastPopupOrWidgetContainer(): void {
    makeOnlyTheLastPopupWithTheActivePopupClass();

    if (popupStorage.havePopupStorageItems()) {
      try {
        // tryToUnblurLastPopup();
      } catch (error) {
      }
    } else {
      // unblurWidgetContainer();
      removeClassThatPopupIsOpen();
    }
  }

  function makeOnlyTheLastPopupWithTheActivePopupClass(): void {
    const popups = popupStorage.getAllPopups();

    if (1 === popups.length) {
      popups[0].element.classList.toggle('jrm-popup-active', true);
    } else if (popups.length > 1) {
      const beforeLastPopup = popups[popups.length - 2];
      const lastPopup = popups[popups.length - 1];

      beforeLastPopup.element.classList.toggle('jrm-popup-active', false);
      lastPopup.element.classList.toggle('jrm-popup-active', true);
    }
  }

  function tryToBlurLastPopup(): void {
    popupStorage.getLastPopupStorageItem().element.style.filter = 'blur(3px)';
  }

  function blurWidgetContainer(): void {
    const widgetContainer = document.getElementById(WIDGET_CONTAINER_ID);

    if (null !== widgetContainer) {
      widgetContainer.style.filter = 'blur(2px)';
    }
  }

  function addClassThatPopupIsOpen(): void {
    if (!document.body.classList.contains('jrm-popup-open')) {
      document.body.classList.add('jrm-popup-open');
    }
  }

  function tryToUnblurLastPopup(): void {
    // popupStorage.getLastPopupStorageItem().element.style.filter = '';
  }

  function unblurWidgetContainer(): void {
    const widgetContainer = document.getElementById(WIDGET_CONTAINER_ID);

    if (null !== widgetContainer) {
      widgetContainer.style.filter = '';
    }
  }

  function removeClassThatPopupIsOpen(): void {
    if (document.body.classList.contains('jrm-popup-open')) {
      document.body.classList.remove('jrm-popup-open');
    }
  }

  function focusToLastPopup(): void {
    try {
      const lastPopup = popupStorage.getLastPopupStorageItem();

      if (undefined !== lastPopup) {
        lastPopup.element.focus();
      }
    } catch (e) {
    }
  }
}
