


























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'BaseTextarea',
  inheritAttrs: false
})
export default class BaseTextarea extends Vue {
  @Prop({
    type: [ String, Number ],
    required: true
  })
  private value!: string | number;

  @Prop({
    type: String,
    default: ''
  })
  private label!: string;

  @Prop({
    type: String,
    default: ''
  })
  private labelClass!: string;

  @Prop({
    type: Boolean,
    default: false
  })
  private isDone!: boolean;

  @Prop({
    type: Boolean,
    default: false
  })
  private isError!: boolean;

  @Prop({
    type: Number,
    default: 1
  })
  private maxDynamicRowsCount!: number;

  private isFocusedInput: boolean = false;

  private get textareaListeners() {
    return Object.assign(
      {},
      this.$listeners,
      {
        input: this.handleInputEvent,
        focus: this.handleFocusEvent,
        blur: this.handleBlurEvent
      }
    );
  }

  private get labelClasses() {
    if (this.isFocusedInput) {
      return this.labelClass;
    }

    return {
      'jrm-input-sts-done': this.isDone,
      'jrm-input-sts-error': this.isError,
      [this.labelClass]: true
    };
  }

  private handleInputEvent(event: any) {
    this.$emit('input', event.target.value);
  }

  private handleFocusEvent() {
    this.isFocusedInput = true;
  }

  private handleBlurEvent() {
    this.isFocusedInput = false;
  }
}
