import Type, { CREDIT_CARD_TYPE } from '@/models/creditCard/type';

export default new Type(
  3,
  16,
  /^9112/,
  /(\d{1,4})(\d{1,4})?(\d{1,4})?(\d{1,4})?/,
  false,
  CREDIT_CARD_TYPE.Belkart,
  /^9112\d{12}$/,
);
