













import Place from '@/models/place';
import PlaceListItem from './PlaceListItem.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'PlaceList',
  components: {
    PlaceListItem
  }
})
export default class PlaceList extends Vue {
  @Prop({
    type: Array,
    required: true
  })
  places!: Place[];
}
