const MAX_LENGTH = 255;

export default class HolderName {
  private readonly _value: string;

  public constructor(value: string) {
    this._value = value;

    if (!HolderName.isValid(value)) {
      throw new Error('Holder name is invalid');
    }
  }

  public static isValid(value: string): boolean {
    return value.length > 0 && value.length <= MAX_LENGTH;
  }

  public get value(): string {
    return this._value;
  }
}
