import i18n from '@/plugins/vue-i18n';
import { IngredientProps, IngredientUnits } from '@/interfaces/models/ingredient';

export default class Ingredient {
  cost: number;
  count: number;
  id: string;
  image: string | null = null;
  name: string;
  description: string;
  unit: IngredientUnits = IngredientUnits.GRAM;
  unitCount: number | null = null;
  groupId: string | null = null;

  [key: string]: any;

  public constructor(ingredientProps: IngredientProps, groupId: string | null = null) {
    this.cost = ingredientProps.cost;
    this.count = ingredientProps.count;
    this.id = ingredientProps.id;
    this.image = ingredientProps.image;
    this.name = ingredientProps.name;
    this.description = ingredientProps.description;
    this.unit = ingredientProps.unit;
    this.unitCount = ingredientProps.unitCount;
    this.groupId = groupId;
  }

  public get hash(): string {
    if (this.groupId !== null) {
      return `${this.id}-${this.groupId}`;
    }

    return this.id;
  }

  public get fullHash(): string {
    return `${this.hash}-${this.count}-${this.cost}-${this.unitCount}`;
  }

  get humanCost() {
    if (0 === this.cost) {
      return i18n.t('menu.ingredient.cost.free');
    }

    return this.fullCost;
  }

  get fullCost() {
    return Math.round(this.count * this.cost * 100) / 100;
  }

  get unitTranslate() {
    switch (this.unit) {
      case IngredientUnits.GRAM:
        return {
          name: i18n.t('menu.ingredient.unit.weight.name'),
          category: i18n.t('menu.ingredient.unit.weight.category')
        };
      case IngredientUnits.MILLILITER:
        return {
          name: i18n.t('menu.ingredient.unit.volume.name'),
          category: i18n.t('menu.ingredient.unit.volume.category')
        };
      default:
        return {
          name: i18n.t('menu.ingredient.unit.count.name'),
          category: i18n.t('menu.ingredient.unit.count.category')
        };
    }
  }
}
