import axiosHelper from '@/helpers/axiosHelper';

export default axiosHelper.mergeAxiosRequestTransformerWithDefaults(
  (data: any, headers: any): any => {
    const projectId = window.localStorage.getItem('jrm.projectId');

    Object.assign(
      headers.common,
      {
        'WIDGET-AUTH-TOKEN': window.localStorage.getItem('jrm.accessToken'),
        'WIDGET-AUTH-PROJECT-ID': projectId,
      }
    );

    return data;
  }
);
