

















































import OrderProductViewPopup from '@/components/ui/popups/takeAway/OrderProductViewPopup/index.vue';
import DefaultImage from '@/components/ui/product/DefaultImage/index.vue';
import getPopupController from '@/lib/vuePopup';
import { PopupControllerPublicProperties } from '@/lib/vuePopup/interfaces';
import Ingredient from '@/models/ingredient';
import OrderProductIngredient from '@/models/orderProductIngredient';
import { Component, Prop, Vue } from 'vue-property-decorator';
import OrderProductListItemIngredient from './OrderProductListItemIngredient.vue';

@Component({
  name: 'OrderProductListItem',
  components: {
    DefaultImage,
    OrderProductListItemIngredient,
  },
})
export default class OrderProductListItem extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  private orderProduct!: any;

  @Prop({
    type: String,
    required: true,
  })
  private currency!: string;

  private popupController: PopupControllerPublicProperties = getPopupController();

  private get firstThreeIngredients(): Ingredient[] {
    return this.flattenIngredients.slice(0, 3);
  }

  private get flattenIngredients(): Ingredient[] {
    return this.orderProduct.ingredients.reduce(
      (
        ingredients: Ingredient[],
        orderProductIngredient: OrderProductIngredient,
      ): Ingredient[] => {
        for (let count = 0; count < orderProductIngredient.count; count++) {
          ingredients.push(orderProductIngredient.ingredient);
        }

        return ingredients;
      },
      [],
    );
  }

  private get countOfIngredientsOverThree(): number {
    return this.flattenIngredients.length - 3;
  }

  private get cost() {
    const ingredientsCost = this.orderProduct.ingredients.reduce(
      (ingredientsCost: number, { cost, count }: any) => ingredientsCost + cost * count,
      0,
    );

    return ingredientsCost + this.orderProduct.product.cost;
  }

  private openOrderProductViewPopup() {
    this.popupController.createPopup(OrderProductViewPopup, {
      propsData: {
        orderProduct: this.orderProduct,
        currency: this.currency,
      },
    });
  }
}
