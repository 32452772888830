





























import Place from '@/models/place';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'PlaceMapMarkerBalloon',
})
export default class PlaceMapMarkerBalloon extends Vue {
  @Prop({
    type: Place,
    required: true,
  })
  private place!: Place;
}
