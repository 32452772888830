import axios, { AxiosTransformer } from 'axios';

export default {
  mergeAxiosResponseTransformerWithDefaults(axiosResponseTransformer: AxiosTransformer): AxiosTransformer[] {
    if (Array.isArray(axios.defaults.transformResponse)) {
      return axios.defaults.transformResponse.concat(axiosResponseTransformer);
    }

    return [axiosResponseTransformer];
  },
  mergeAxiosRequestTransformerWithDefaults(axiosRequestTransformer: AxiosTransformer): AxiosTransformer[] {
    if (Array.isArray(axios.defaults.transformRequest)) {
      return axios.defaults.transformRequest.concat(axiosRequestTransformer);
    }

    return [axiosRequestTransformer];
  }
};
