






































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'PopupWrapper'
})
export default class PopupWrapper extends Vue {
  @Prop({
    type: Boolean,
    default: true,
  })
  hasCloseButton!: boolean;

  private close() {
    this.$emit('close');
  }
}
