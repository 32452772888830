import Type from '@/models/creditCard/type';

const CVC_REGEX = /^\d{3,4}$/;
const AVAILABLE_CVC_LENGTHS = [3, 4];

export default class Cvc {
  private readonly _value: string;

  public constructor(value: string) {
    this._value = value;

    if (!Cvc.isValid(value)) {
      throw new Error('Cvc is invalid');
    }
  }

  public static isValid(value: string, type: Type | undefined = undefined): boolean {
    if (!CVC_REGEX.test(value)) {
      return false;
    }

    if (undefined === type) {
      return AVAILABLE_CVC_LENGTHS.includes(value.length);
    }

    return type.cvcLength === value.length;
  }

  public static format(cvc: string): string {
    const parsedCvc = cvc.slice(0, 4);

    return isNaN(parseInt(parsedCvc)) ? '' : parsedCvc;
  }

  public get value(): string {
    return this._value;
  }
}
