export type IngredientProps = {
  cost: number;
  count: number;
  id: string;
  image: string | null;
  name: string;
  description: string;
  unit: IngredientUnits;
  unitCount: number;

  [key: string]: any;
};

export enum IngredientUnits {
  UNIT = 'UNIT',
  GRAM = 'GRAM',
  MILLILITER = 'MILLILITER'
}
