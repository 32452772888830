import string from '@/utils/format/string';
import parseIntStrict from 'parse-int';
import parseYear from 'parse-year';

const CARD_EXPIRATION_DATE_REGEX = /^(\d\d) (\d\d(\d\d)?)$/;

export default class ExpirationDate {
  private readonly _month: number;
  private readonly _year: number;

  public constructor(month: number, year: number) {
    this._month = month;
    this._year = year;

    if (!ExpirationDate.isValid(month, year)) {
      throw new Error('Expiration date is invalid');
    }
  }

  public static createFromString(expirationDate: string): ExpirationDate {
    const [month, year] = ExpirationDate.getMonthAndYearFromExpirationDate(expirationDate);

    return new ExpirationDate(month, year);
  }

  public static isValid(month: number, year: number): boolean {
    const now = new Date();
    const fullYear = ExpirationDate.parseYear(year, true);
    const currentYear = now.getFullYear();

    if (currentYear === fullYear) {
      return month >= now.getMonth() && month < 13;
    }

    return fullYear > currentYear && month > 0 && month < 13;
  }

  public static formatExpirationDate(expirationDate: string, previousExpirationDate: string | null = null): string {
    const [month, year] = ExpirationDate.getMonthAndYearFromExpirationDate(expirationDate.slice(0, 5));

    if (expirationDate.length === 0) {
      return expirationDate;
    } else if (previousExpirationDate && previousExpirationDate.length === 3 && expirationDate.length === 2) {
      return Math.floor(month / 10).toString();
    } else if (expirationDate.length === 2) {
      return `${string.t2(month)}/`;
    } else if (expirationDate.length > 3 && undefined === year) {
      return previousExpirationDate || '';
    } else if (undefined !== year && undefined !== month) {
      return `${string.t2(month)}/${year}`;
    } else if (undefined === month) {
      return previousExpirationDate || '';
    }

    return expirationDate;
  }

  public static getMonthAndYearFromExpirationDate(expirationDate: string): [number, number] {
    const [month, year] = expirationDate.replace(CARD_EXPIRATION_DATE_REGEX, '$1/$2').split('/');

    return [ExpirationDate.parseMonth(month), ExpirationDate.parseYear(year)];
  }

  public static parseMonth(month: string | number): number {
    return parseIntStrict(month);
  }

  public static parseYear(year: string | number, expand: boolean = false): number {
    return parseYear(year, expand);
  }

  public get value(): string {
    return `${this._month}/${this._year}`;
  }

  public get month(): number {
    return this._month;
  }

  public get year(): number {
    return this._year;
  }
}
