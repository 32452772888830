

























































































































import PopupWrapper from '@/components/ui/PopupWrapper/index.vue';
import DefaultImage from '@/components/ui/product/DefaultImage/index.vue';
import OrderProduct from '@/models/orderProduct';
import Place from '@/models/place';
import Product from '@/models/product';
import TakeAway from '@/models/takeAway';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { State, Mutation, Getter } from 'vuex-class';

@Component({
  name: 'ProductAddPopup',
  components: {
    DefaultImage,
    PopupWrapper,
  },
})
export default class ProductAddPopup extends Vue {
  @Prop({
    type: Product,
    required: true,
  })
  private product!: Product;

  private orderProduct: OrderProduct = OrderProduct.createOrderProductWithZeroCount({
    product: this.product,
  });

  private get canIncrementProductCount(): boolean {
    return this.selectedPlace.settings.widget.maxProductCount > this.order.productsCount + this.orderProduct.count;
  }

  private get isPlaceOpen(): boolean {
    return this.selectedPlace.isPlaceOpen(this.nowInSelectedPlaceInSeconds);
  }

  @State('selectedPlace', {
    namespace: 'place',
  })
  private selectedPlace!: Place;

  @State('order', {
    namespace: 'takeAway/order',
  })
  private order!: TakeAway;

  @Getter('nowInSelectedPlaceInSeconds', {
    namespace: 'timer',
  })
  private nowInSelectedPlaceInSeconds!: number;

  @Getter('takeAwayOrDeliveryEnabled', {
    namespace: 'place',
  })
  private takeAwayOrDeliveryEnabled!: boolean;

  private addOrderProductToOrder() {
    if (this.isPlaceOpen) {
      this.addOrderProductToOrderAction(this.orderProduct);
      this.close();
    }
  }

  private incrementOrderProductCount() {
    if (this.isPlaceOpen && this.canIncrementProductCount) {
      this.orderProduct.increment();
    }
  }

  private decrementOrderProductCount() {
    if (this.isPlaceOpen) {
      this.orderProduct.decrement();
    }
  }

  private close() {
    this.$destroy();
  }

  @Mutation('addOrderProductToOrder', {
    namespace: 'takeAway/order',
  })
  addOrderProductToOrderAction!: (orderProduct: OrderProduct) => void;
}
