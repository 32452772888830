import axiosHelper from '@/helpers/axiosHelper';

export default axiosHelper.mergeAxiosResponseTransformerWithDefaults(
  (order: any) => {
    if (order.transaction) {
      order.transaction.status = order.transaction.status.toUpperCase();
    }

    return order;
  }
);
