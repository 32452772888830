import Type from '@/models/creditCard/type';
import types from '@/models/creditCard/types';

export default class CreditCardTypeRepository {
  private readonly _types: Type[];

  public constructor() {
    this._types = types;
  }

  public findByNumber(number: string, eager: boolean = true): Type | undefined {
    return this._types.find(
      (type: Type): boolean => type.test(number, eager),
    );
  }
}
