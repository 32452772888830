import { MenuCategoryProps } from '@/interfaces/models/menuCategory';
import { ProductProps } from '@/interfaces/models/product';
import Product from '@/models/product';

export default class MenuCategory {
  id: string;
  name: string;
  priority: number;
  products: Product[];

  constructor(menuCategoryProps: MenuCategoryProps) {
    this.id = menuCategoryProps.id;
    this.name = menuCategoryProps.name;
    this.priority = menuCategoryProps.priority;
    this.products = menuCategoryProps.products.map((productProps: ProductProps) => new Product(productProps));
  }
}
