










import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'DefaultImage',
})
export default class DefaultImage extends Vue {

}
