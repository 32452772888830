import Vue from 'vue';
import Vuex, { ActionContext } from 'vuex';
import modules from '@/store/index';
import projectApi from '@/api/services/project.service';
import { ProjectPropsResponse } from '@/interfaces/api/project';

Vue.use(Vuex);

export interface RootState {
  isLoadingProjectProps: boolean;
  projectId: string | null;
  beforeInstallPromptEvent: Event | null;
}

export default new Vuex.Store<RootState>({
  state: {
    isLoadingProjectProps: true,
    projectId: null,
    beforeInstallPromptEvent: null,
  },
  actions: {
    fetchProjectPropsByProjectId({ commit, dispatch }: ActionContext<RootState, RootState>, projectId: string) {
      commit('setIsLoadingProjectProps', true);
      return projectApi.fetchProjectPropsByProjectId(projectId)
        .then((response: ProjectPropsResponse) => {
          dispatch('place/setPlaces', response.places);
          dispatch('timer/startTimer', response.now);
          commit('setIsLoadingProjectProps', false);
        });
    },
  },
  mutations: {
    setIsLoadingProjectProps(state: any, isLoadingProjectProps: boolean) {
      state.isLoadingProjectProps = isLoadingProjectProps;
    },
    setApiKey(state, projectId: string) {
      window.localStorage.setItem('jrm.projectId', projectId);

      state.projectId = projectId;
    },
    setBeforeInstallPromptEvent(state, beforeInstallPromptEvent: Event) {
      state.beforeInstallPromptEvent = beforeInstallPromptEvent;
    },
  },
  modules,
  strict: process.env.NODE_ENV !== 'production'
});
