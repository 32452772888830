



































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { numeric, required, maxLength, minLength } from 'vuelidate/lib/validators';
import { State } from 'vuex-class';
import userService from '@/api/services/user.service';

@Component({
  name: 'CodeForm',
  validations: {
    code: {
      minLength: minLength(5),
      maxLength: maxLength(5),
      required,
      numeric,
    },
  },
})
export default class CodeForm extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  private phone!: string;

  @Prop({
    type: String,
    required: true,
  })
  private currentCode!: string;

  private code: string = this.currentCode;
  private time: number = 120;

  @State('projectId')
  private projectId!: string;

  @State('utcNowInSeconds', {
    namespace: 'timer',
  })
  private utcNowInSeconds!: number;

  @Watch('utcNowInSeconds')
  private onUtcNowInSecondsChange() {
    if (this.time > 0) {
      this.time--;
    }
  }

  @Watch('code')
  private onCodeChanged(code: string) {
    this.$emit('update:currentCode', code);
  }

  @Watch('currentCode', {
    immediate: true,
  })
  private onCurrentCodeChanged(currentCode: string) {
    if (currentCode !== this.code) {
      this.code = this.currentCode;
    }
  }

  private created() {
    const cancelOtpSubscriber = this.subscribeForOtp();

    this.$on(
      'hook:beforeDestroy',
      () => {
        cancelOtpSubscriber.abort();
      },
    );
  }

  private subscribeForOtp(): AbortController {
    const abortController = new AbortController();

    if ('OTPCredential' in window) {
      navigator.credentials.get(<any> {
        otp: { transport: ['sms'] },
        signal: abortController.signal,
      }).then((otp: any) => {
        this.$emit('update:currentCode', otp.code);
        this.$emit('login');
      }).catch((err) => {
        console.log(err);
      });
    }

    return abortController;
  }

  private async resendSms() {
    if (this.time <= 0) {
      try {
        const cancelOtpSubscriber = this.subscribeForOtp();

        this.$on(
          'hook:beforeDestroy',
          () => {
            cancelOtpSubscriber.abort();
          },
        );

        await userService.getLoginCodeByUserPhone({
          phone: this.phone,
          projectId: this.projectId,
        });
      } catch (e) {
      }

      this.time = 120;
    }
  }
}
