import stringFormatter from '@/utils/format/string';
import dateFormatter, {
  ONE_MINUTE_IN_SECONDS,
  ONE_SECOND_IN_MILLISECONDS
} from '@/utils/format/date';
import VueI18n from 'vue-i18n';

export default class SystemDate extends Date {
  static createFromLocalTimestamp(timestamp: number) {
    return new SystemDate(
      SystemDate.getUTCTimestampFromLocalTimestamp(timestamp)
    );
  }

  static getUTCTimestampFromLocalTimestamp(timestamp: number): number {
    return timestamp + SystemDate.getTimezoneOffsetInMilliseconds();
  }

  static getTimezoneOffsetInMilliseconds(): number {
    const timezoneInMinutes: number = new Date().getTimezoneOffset();

    return timezoneInMinutes * ONE_MINUTE_IN_SECONDS * ONE_SECOND_IN_MILLISECONDS;
  }

  getRightMonth(): number {
    return this.getMonth() + 1;
  }

  getPaddedFullMonth(): string {
    return stringFormatter.t2(this.getRightMonth());
  }

  getHumanFullMonth(): string | VueI18n.TranslateResult {
    return dateFormatter.getHumanMonthByDate(this);
  }

  getHumanFullWeek(): string | VueI18n.TranslateResult {
    return dateFormatter.getHumanFullWeekByDate(this);
  }

  getHumanTime() {
    return `${this.getPaddedHours()}:${this.getPaddedMinutes()}`;
  }

  getPaddedDate(): string {
    return stringFormatter.t2(this.getDate());
  }

  getPaddedHours(): string {
    return stringFormatter.t2(this.getHours());
  }

  getPaddedMinutes(): string {
    return stringFormatter.t2(this.getMinutes());
  }

  getPaddedSeconds(): string {
    return stringFormatter.t2(this.getSeconds());
  }

  isSameDays(date: Date): boolean {
    return this.getFullYear() === date.getFullYear() &&
      this.getMonth() === date.getMonth() &&
      this.getDate() === date.getDate();
  }

  getStartOfDay(): SystemDate {
    const newDate = new SystemDate(this.getTime());

    newDate.setHours(0, 0, 0, 0);

    return newDate;
  }

  getFullHash() {
    return `${this.getFullYear()}-${this.getPaddedFullMonth()}-${this.getPaddedDate()}`;
  }

  getSmallHash() {
    return `${this.getFullYear()}-${this.getPaddedFullMonth()}`;
  }

  getLocalTime() {
    return this.getTime() - SystemDate.getTimezoneOffsetInMilliseconds();
  }

  getEuropeanDay() {
    const day = this.getDay();

    return day === 0 ? 6 : day - 1;
  }

  getTodayTimeInSeconds() {
    return this.getLocalTime() % 86400000 / 1000;
  }

  getHumanDateString(): any {
    let now = new SystemDate(Date.now()).getStartOfDay().getLocalTime();

    return dateFormatter.getHumanDateString(this, now);
  }

  clone() {
    return new SystemDate(this.getTime());
  }

  startOfNextDay() {
    this.setHours(0, 0, 0, 0);
    this.setDate(this.getDate() + 1);

    return this;
  }

  addDays(daysCount: number) {
    this.setDate(this.getDate() + daysCount);

    return this;
  }
}
