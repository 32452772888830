



































import { Component, Prop, Vue } from 'vue-property-decorator';
import PopupWrapper from '@/components/ui/PopupWrapper/index.vue';

@Component({
  name: 'PlaceCommentPopup',
  components: {
    PopupWrapper,
  },
})
export default class PlaceCommentPopup extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  private comment!: string;

  private close() {
    this.$destroy();
  }
}
