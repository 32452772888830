import { RootState } from '@/store';
import { Store } from 'vuex';
import Logger from './logger';
import camelCase from 'lodash.camelcase';

export default class EventEmitter {
  private readonly store: Store<RootState> | any;
  private readonly actionPrefix = 'SOCKET_';
  private readonly mutationPrefix = 'SOCKET_';

  constructor(vuex: any) {
    if (vuex !== undefined) {
      Logger.info('Vuex adapter enabled');
      Logger.info(vuex.mutationPrefix ? 'Vuex socket mutations enabled' : 'Vuex socket mutations disabled');
      Logger.info('Vuex socket actions enabled');
    } else {
      Logger.info('Vuex adapter disabled');
      Logger.info('Vuex socket mutations disabled');
      Logger.info('Vuex socket actions disabled');
    }

    this.store = vuex.store;

    if (vuex.actionPrefix) {
      this.actionPrefix = vuex.actionPrefix;
    }

    if (vuex.actionPrefix) {
      this.mutationPrefix = vuex.mutationPrefix;
    }
  }


  public dispatchStore(event: string, args: any) {
    if (event !== 'ping' && event !== 'pong') {
      this.dispatchActionByEventNameAndSocketArguments(event, args);

      this.commitMutationByEventNameAndSocketArguments(event, args);
    }
  }

  private dispatchActionByEventNameAndSocketArguments(event: string, args: any) {
    const actionName = camelCase(this.actionPrefix + event);

    for (let namespacedAction in this.store._actions) {
      const action = namespacedAction.split('/').pop();

      if (action === actionName) {
        // Logger.info(`Dispatching Action: ${namespacedAction} with data: `, args);
        // window.console.info(`%cJustReserveMe: %cDispatching Action: ${namespacedAction} with data: `, 'color: green; font-weight: 600', 'color: #666', args);

        this.store.dispatch(namespacedAction, args);
      }
    }
  }

  private commitMutationByEventNameAndSocketArguments(event: string, args: any) {
    const mutationName = camelCase(this.mutationPrefix + event);

    for (let namespacedMutation in this.store._mutations) {
      const mutation = namespacedMutation.split('/').pop();

      if (mutation === mutationName) {
        // Logger.info(`Commiting Mutation: ${namespacedMutation} with data: `, args);

        this.store.commit(namespacedMutation, args);
      }
    }
  }
}
